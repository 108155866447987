import React from 'react'
import slug from 'slug'
//import $ from "jquery";

import {isEmpty} from "lodash"

import ModalExample from "./modal"

import {
  Link
} from "react-router-dom"

export default class Books extends React.Component {

  constructor(props){
    super(props)
    this.addNew = this.addNew.bind(this);
    this.state = {
      showModal: false
    }
  }

  componentDidMount(){
    this.props.getBooks(this.props.currentUser.email)
  }

  addNew(ev){
    this.props.onAddBook({
      title: this.refs.article_title.value, 
      description: this.refs.textArea.value ,
      user_id: this.props.currentUser.email,
      public: this.refs.privateCheck.checked
    }, 

    {success: (book)=> {
      this.setState({
        showModal: false
      }, ()=> this.props.getBooks(this.props.currentUser.email) )
      //$("#exampleModal").modal('hide');
      //this.props.history.push("/books/" + book.slug)
    }}

    )
  }

  renderNewBookForm(){
    return (
      <form>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">
            Book Title
          </label>
          <input 
            type="text" 
            className="form-control" 
            id="exampleInputEmail1" 
            aria-describedby="emailHelp" 
            placeholder="Enter article title"
            ref="article_title"
          />
          <small id="emailHelp" className="form-text text-muted">
            Write the book's title.
          </small>
        </div>

        <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">Summary</label>
            <textarea className="form-control" rows="3" ref="textArea">
            </textarea>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <input type="checkbox" 
              ref="privateCheck" 
              className="form-check-input"/>
            Keep this book public
          </label>
        </div>
      </form>
    )
  }

  createNewModal = (e)=>{
    e.preventDefault()
    this.setState({
      showModal: true
    })
  }

  handleConfirm = (e)=>{
    this.addNew(e)
  }

  removeBook = (id)=>{
    this.props.removeBook({id: id} , {
       success: ()=>{
          this.props.getBooks(this.props.currentUser.email)
        }, 
        error: ()=>{
          
        }
    })
  }

  render(){
    return (
      <React.Fragment>

        
        {
          this.props.books ? 
          <div className="container-fluid">

            {this.props.renderMenu()}

            <div className="col-12">


              <p className="float-right">
                
                <a className="btn btn-success btn-lg" 
                  href="#" onClick={this.createNewModal}>
                  Create a Book
                </a>

              </p>


              <ModalExample 
                toggle={()=> this.setState({showModal: !this.state.showModal})}
                isOpen={this.state.showModal} 
                handleConfirm={this.handleConfirm}>
                {this.renderNewBookForm()}
              </ModalExample>
            
              <div className="mt-3 mb-3">
                <h1>Books</h1>
              </div>

              <div className="row">

                {
                  Object.keys(this.props.books).map( (o,i)=>{
                    return ( <BookItem 
                            key={i} 
                            item={this.props.books[o]}
                            removeBook={this.removeBook}
                            getBooks={this.props.getBooks} />
                            )
                  })
                }
              </div>

            </div>
            
          </div> : <Loader/>
        }
      </React.Fragment>
    )
  }

}

class BookItem extends React.Component {


  removeBookHandler = (e)=>{
    e.preventDefault()
    if( window.confirm("are you sure?"))
      this.props.removeBook(this.props.item.slug)
  }

  render(){
    return (



      <div className="col-sm-6 col-xs-12 col-md-4 col-lg-4 mt-sm theme-light pb-4">

        <Link 
          className="bg-darkest display-block height-full scene"
          to={"/books/" + this.props.item.slug}>


          <div className="hero-background hero-overlay" 
            style={{backgroundImage: `url('${this.props.item.image}')`}}>
            <div className="scene-content  pt-4 pl-4 pr-4 pb-5 height-full">
              <p className="type-caption">
                <i className={`fa ${this.props.item.public ? 'fa-unlock' : 'fa-lock'}`}/>
                {" "}
                {this.props.item.public ? 'public' : 'private'}
                <button href="#" 
                  className="card-link btn-danger float-right" 
                  onClick={this.removeBookHandler} >
                  REMOVE
                </button>
              </p>
              <div className="type-display2 text-brake font-sans">
              {this.props.item.title}
              </div>

              <p className="card-text">
              {this.props.item.description}


              </p>

            </div>
          </div>

        </Link>

      </div>


    )
  }
}

class Loader extends React.Component {
  constructor(props){
    super(props)
  }

  render(){
    return(
      <div className="mx-auto" style={{width: "95%"}}>

        <div className="jumbotron" style={{backgroundColor: "#fff"}}>
          <h1 className="display-3">Loading Data</h1>
          <p className="lead">wait a few seconds please</p>
          <hr className="my-4"/>
          <img src={"https://loading.io/spinners/ball/lg.bouncing-circle-loading-icon.gif"} />

        </div>
      </div>
    )
  }
}