import ActionTypes from '../constants/action_types';
const default_article = {books: {} }
export function booksReducer(state = default_article, action) {
  switch(action.type) {

    case ActionTypes.GetBooks: {
      return Object.assign({}, state, {
        books: action.data
      });
    }

    default:
      return state;
  }
}