import React from "react"
import config from "../constants/config"
import {RoundedBtn} from "./styledBlocks";
import {uniq} from "lodash"
// Broadcast Types
const JOIN_ROOM = "JOIN_ROOM";
const EXCHANGE = "EXCHANGE";
const REMOVE_USER = "REMOVE_USER";
// Ice Credentials
const ice = { iceServers: [{ urls: "stun:stun.l.google.com:19302" }] };


export default class RtcView extends React.Component {

   constructor(props){
    super(props)
    this.currentUser = this.props.currentUser //Math.random();

    this.localVideo = null
    this.remoteVideoContainer = null

    // Objects
    this.pcPeers = {};
    this.localstream = null
    // data objects
    this.localConnection = null
    this.remoteConnection = null
    this.sendChannel = null
    this.receiveChannel = null

    this.App = {
      session: {}
    }

    this.state = {
      users: []
    }
  }

  componentDidMount(){
    this.localVideo = this.refs.video
    this.remoteVideoContainer = this.refs.remote_video

    window.t = this

    this.props.video ?
      this.initVideoSession() : setTimeout(()=> this.handleJoinSession(), Math.random() )
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.diff !== this.props.diff){
      this.sendData()
    }

    if(prevProps.selectionPosition !== this.props.selectionPosition){
      this.sendPosition()
    }

    if(prevProps.video !== this.props.video){
      this.handleLeaveSession()
    
      this.props.video ?
        this.initVideoSession() : 
        setTimeout(()=> this.handleJoinSession(), Math.random() )
    }


  }

  componentWillUnmount(){
    this.handleLeaveSession()
  }

  initVideoSession = ()=>{
    navigator
    .mediaDevices
    .getUserMedia({
      audio: true,
      video: true
    })
    .then(stream => {
      this.localstream = stream;
      this.localVideo.srcObject = stream;
      this.localVideo.muted = true;
      setTimeout(()=> this.handleJoinSession(), Math.random() )
    })
    .catch(this.logError)
  }

  handleJoinSession = () => {
    this.App.session = window.ChannelApp.cable.subscriptions.create(
      { 
        channel: "RtcChannel", 
        room: this.props.book + "-" + this.props.article
      }, 
      {
      connected: () => {
        this.broadcastData({
          type: JOIN_ROOM,
          from: this.currentUser
        });
      },
      received: data => {
        console.log("received", data);
        if (data.from === this.currentUser) return;
        switch (data.type) {
          case JOIN_ROOM:
            return this.joinRoom(data);
          case EXCHANGE:
            if (data.to !== this.currentUser) return;

            this.setState({
              users: uniq(this.state.users.concat(data.from))
            })
            return this.exchange(data);
          case REMOVE_USER:
            return this.removeUser(data);
          default:
            return;
        }
      }
    });
  };

  handleLeaveSession = () => {
    for (var user in this.pcPeers) {
      this.pcPeers[user].close();
    }
    this.pcPeers = {};

    this.App.session.unsubscribe();

    if(this.remoteVideoContainer)
      this.remoteVideoContainer.innerHTML = "";

    this.broadcastData({
      type: REMOVE_USER,
      from: this.currentUser
    });

    if(this.localstream)
      this.localstream.stop()
  };

  joinRoom = data => {
    this.createPC(data.from, true);
  };

  removeUser = data => {
    console.log("removing user", data.from);
    let video = document.getElementById(`remoteVideoContainer+${data.from}`);
    video && video.remove();
    console.log("peers", this.pcPeers)
    delete this.pcPeers[data.from];
  };

  createPC = (userId, isOffer) => {
    let pc = new RTCPeerConnection(ice);
    this.pcPeers[userId] = pc;
    // DISABLE VIDEO
    if(this.props.video) 
      pc.addStream(this.localstream)

    pc.ondatachannel = this.receiveChannelCallback

    this.sendChannel = pc.createDataChannel('sendDataChannel');
    console.log('Created send data channel');
    this.sendChannel.onopen = this.onSendChannelStateChange;
    this.sendChannel.onclose = this.onSendChannelStateChange;

    isOffer &&
      pc
        .createOffer()
        .then(offer => {
          pc.setLocalDescription(offer);
          this.broadcastData({
            type: EXCHANGE,
            from: this.currentUser,
            to: userId,
            sdp: JSON.stringify(pc.localDescription)
          });
        })
        .catch(this.logError);

     pc.onicecandidate = event => {
      event.candidate &&
        this.broadcastData({
          type: EXCHANGE,
          from: this.currentUser,
          to: userId,
          candidate: JSON.stringify(event.candidate)
        });
    };

     pc.onaddstream = event => {
      const element = document.createElement("video");
      element.id = `remoteVideoContainer+${userId}`;
      element.autoplay = "autoplay";
      element.srcObject = event.stream;
      element.style = "border: 1px solid red;width: 100px;height: 100px;"
      this.remoteVideoContainer.appendChild(element);
    };

     pc.oniceconnectionstatechange = event => {
      if (pc.iceConnectionState == "disconnected") {
        console.log("Disconnected:", userId);
        this.broadcastData({
          type: REMOVE_USER,
          from: userId
        });
      }
    };

     return pc;
  };

  exchange = data => {
    let pc;

    if (!this.pcPeers[data.from]) {
      pc = this.createPC(data.from, false);
    } else {
      pc = this.pcPeers[data.from];
    }

    if (data.candidate) {
      pc
        .addIceCandidate(new RTCIceCandidate(JSON.parse(data.candidate)))
        .then(() => console.log("Ice candidate added"))
        .catch(this.logError);
    }

    if (data.sdp) {
      const sdp = JSON.parse(data.sdp);

      pc
        .setRemoteDescription(new RTCSessionDescription(sdp))
        .then(() => {
          if (sdp.type === "offer") {
            pc.createAnswer().then(answer => {
              pc.setLocalDescription(answer);
              this.broadcastData({
                type: EXCHANGE,
                from: this.currentUser,
                to: data.from,
                sdp: JSON.stringify(pc.localDescription)
              });
            });
          }
        })
        .catch(this.logError);
    }
  };

  broadcastData = data => {
    const a = {
      room: this.props.book + "-" + this.props.article
    }
    fetch(`${config.endpoint}/rtc`, {
      method: "POST",
      body: JSON.stringify(Object.assign({}, data, a)),
      headers: { "content-type": "application/json" }
    });
  };

  logError = error => console.warn("Whoops! Error:", error);


  receiveChannelCallback = (event)=>{
    console.log('Receive Channel Callback');
    this.receiveChannel = event.channel;
    this.receiveChannel.onmessage = this.onReceiveMessageCallback;
    this.receiveChannel.onopen = this.onReceiveChannelStateChange;
    this.receiveChannel.onclose = this.onReceiveChannelStateChange;
  }

  sendData =()=> {
    const data = this.props.diff
    //console.log("data diff: ", data)
    if(this.sendChannel && this.sendChannel.readyState === "open"){
      console.log('Sent Data: ' + data);
      this.sendChannel.send(data);
    }
  }

  sendPosition = ()=>{
    let data = this.props.selectionPosition
    data = Object.assign({}, data, {user: this.currentUser})
    const positionData = JSON.stringify({position: data})
    console.log(positionData)
    if(this.sendChannel && this.sendChannel.readyState === "open"){
      console.log('Sent Position: ' + positionData);
      this.sendChannel.send(positionData);
    } 
  }

  onReceiveMessageCallback = (event)=> {
    console.log('Received Message');
    this.props.handleRTCMessage(event.data)
    //dataChannelReceive.value = event.data;
    //console.log(event.data)
  }

  onSendChannelStateChange = ()=> {
    const readyState = this.sendChannel.readyState;
    console.log('Send channel state is: ' + readyState);
    /*if (readyState === 'open') {
      dataChannelSend.disabled = false;
      dataChannelSend.focus();
      sendButton.disabled = false;
      closeButton.disabled = false;
    } else {
      dataChannelSend.disabled = true;
      sendButton.disabled = true;
      closeButton.disabled = true;
    }*/
  }

  onReceiveChannelStateChange =()=> {
    const readyState = this.receiveChannel.readyState;
    console.log(`Receive channel state is: ${readyState}`);
  }

  render(){
    console.log(this.pcPeers)
    return <div style={{ 
                  position: 'sticky', 
                  top: '0px',
                  zIndex: '10',
                  background: 'white',
                  padding: '10px',
                  borderBottom: '1px solid #ccc'
              }}>

            <div>
              <RoundedBtn 
                className={`btn btn-outline${this.props.video ? '-success active': '-secondary'}`}
                onClick={this.props.toggleVideoSession}>
                  <i className="fa fa-video"></i>
              </RoundedBtn>
              {" "}
              <b>you: {" "}</b>
              <span id="current-user">
                {this.currentUser}
              </span>

              {
                this.state.users.length > 0 ?
                <span>
                {" "}
                <b>connected users: </b>
                {" "}
                {this.state.users.map((o)=>(
                  <span>{o}</span>
                ))}
                </span> : null
              }
            </div>

        
            <div className="d-flex">

              <video id="local-video" 
                ref="video" 
                className="m-2"
                autoPlay style={{
                  border: '1px solid blue',
                  width: '100px',
                  height: '100px',
                  display: this.props.video ? 'block' : 'none'
                }}>
              </video>

              <div id="remote-video-container" 
                   className="m-2"
                   ref="remote_video">
              </div>

            </div>

            {
              this.props.manualJoin ?
              <div>
              <button onClick={this.handleJoinSession}>
                Join Room
              </button>

              <button onClick={this.handleLeaveSession}>
                Leave Room
              </button></div> : null
            }


     </div>


   }
} 