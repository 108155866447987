

export const BOOKS = `
  query Books($page: Int)  {
    books(page: $page) {
      collection {
        title
        slug
        description
        image
        public
      }
      meta
    }
  }
`;

export const INVITED_USERS_BOOK = `
  query UserBook($id: String!)  {
    userBook(id: $id) {
      users{
        email
      }
    }
  }
`;

export const USER_SEARCH = `
  query UserSearch($bookId: String!, $query: String!)  {
    userSearch(bookId: $bookId, query: $query) {
      email
    }
  }
`;

export const USER_BOOKS = `
  query UserBooks($page: Int)  {
    userBooks(page: $page) {
      collection {
        title
        slug
        description
        image
        public
      }
      meta
    }
  }
`;


export const BOOK = `
  query Book($id: String!)  {
    book(id: $id) {
      title
      slug
      description
      public
      image
      treeData
    }
  }
`;

export const USER_BOOK = `
  query UserBook($id: String!)  {
    userBook(id: $id) {
      title
      slug
      description
      public
      image
      treeData
      password
    }
  }
`;

export const BOOK_TREE = `
  query Book($id: String!){
    book(id: $id) {
      treeData
    }
}

`

export const ARTICLE = `
  query Article($bookId: String!, $id: String)  {
    article(bookId: $bookId, id: $id) {
      title
      content
      public
      slug
      next{
        slug
        title
      }
      prev{
        slug
        title
      }
    }
  }
`;


export const USER_ARTICLE = `
  query UserArticle($bookId: String!, $id: String)  {
    userArticle(bookId: $bookId, id: $id) {
      title
      content
      public
      slug
      next{
        slug
        title
      }
      prev{
        slug
        title
      }
    }
  }
`;


