import React from 'react'

export default class LockedView extends React.Component {
  constructor(props){
    super(props)
  }

  render(){
    return(
      <div className="mx-auto" style={{width: "95%"}}>

        <div className="jumbotron" style={{backgroundColor: "#fff"}}>
          <h1 className="display-3">Access Required</h1>
          <p className="lead">Please log in</p>
          <hr className="my-4"/>
          <a href="#" 
            className="btn btn-primary btn-lg"
            onClick={this.props.handleClick}>
            Login with Google
          </a>

        </div>
      </div>
    )
  }
}