import React, {Component} from 'react'

export default class UserMenu extends Component{
  render(){
    return <div className={`form-inline ${this.props.className ? this.props.className : ''}`}>
      <ul className={`navbar-nav ${this.props.navbarClass || 'mr-auto'}`}>
        <li className="nav-item active">

          {
            this.props.authenticated ? 
              <a className="nav-link" href="#">
                {this.props.currentUser.email}
                <span>
                  <img 
                    //className="img-thumbnail"
                    src={this.props.currentUser.avatar}
                    style={{"marginLeft": "10px"}}
                    width="28"
                    height="28"
                  />
                </span>
              </a> : <a className="nav-link" href="#">Home 
          <span className="sr-only">(current)</span>
          </a>
          }
        </li>

        <li className="nav-item">

          {
            this.props.authenticated ? 
             <a className="nav-link" href="#" onClick={this.props.handleLogout}>
              logout
            </a> 
            : <a className="nav-link" href="#">SignIn </a>
          }
        </li>
      </ul>
    </div>
  }
}