import styled from '@emotion/styled'
import sky from '../images/sky.png'


export const ArticlePad = styled.div`

  @media (max-width: 640px){
    margin: 1rem !important;
    margin-top: 5px !important;
    padding: 1rem;
  }

  @media only screen and (min-width: 1200px){
    margin: 9rem !important;
    margin-top: 18px !important;
  }

  background: white;

  padding: 2rem;
  margin: 2rem !important;
  margin-top: 18px !important;

  border: 1px solid #dde1eb;
  -webkit-box-shadow: 0 4px 8px 0 hsla(212,9%,64%,.16), 0 1px 2px 0 rgba(39,45,52,.08);
  box-shadow: 0 4px 8px 0 hsla(212,9%,64%,.16), 0 1px 2px 0 rgba(39,45,52,.08);

`

export const RoundedBtn = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0px;
  i{
    margin-top: 6px;
    text-indent: 1px;
  }
`

export const Sidebar = styled.div`
  background-color: #eeeceb;
  width: 57rem;

  @media (min-width: 768px){
    margin-left: 0;
  }

  min-height: 100vh;
  margin-left: -57rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
`

export const Nav = styled.nav`
  a{
    padding-bottom: 2px;
    border-bottom: 1px solid #e0e0e0;
    color: #443e3c;
    text-decoration: none; 
    text-transform: uppercase;   
  }
`

export const Card = styled.div`
  border: none;
`

export const FixedCol = styled.div`
  position: fixed;
  overflow:hidden;
`

export const TitleContent = styled.div`
  padding:24px;
  padding-top: 20px;
  //background: url(${sky});
`

export const TitleLogo = styled.p`
  margin-bottom: 55px;
  text-transform: uppercase;
`

export const Main = styled.div`
  padding: 0px;
  width: 100%;
}`

export const ContentBand = styled.div`
  padding: 10px;
`

export const UserInfo = styled.div`
  position: 'fixed';
`

export const CardTitle = styled.h3`
    margin-bottom: 2rem;
    font-size: 3rem;
    a{
      color: black;
    }
`