import React, {Component} from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  max-width: 42em;
`

export default class Cover extends Component {

  render(){
    return <div className="text-center col-12">
              <Container className="d-flex h-100 p-3 mt-5 mx-auto d-flex justify-content-center">
                <div className="inner cover">
                  <h1 className="cover-heading">
                    {this.props.title}
                  </h1>
                  <div className="lead">
                    {this.props.description}
                  </div>

                  <div className="mt-2">
                    {this.props.children }
                  </div>
                </div>
              </Container>
            </div>
  }
}