import React from 'react'
import Sidebar from './sidebar.js'
import Dante from 'Dante2/package/Dante.cjs.js'
import uuidV4 from 'uuid/v4';
import Loader from './loader.js'
import slug from 'slug'
import posed, {PoseGroup} from 'react-pose';

import {DanteImagePopoverConfig} from 'Dante2/package/es/components/popovers/image.js'
import {DanteAnchorPopoverConfig} from 'Dante2/package/es/components/popovers/link.js'
import {DanteInlineTooltipConfig} from 'Dante2/package/es/components/popovers/addButton.js' //'Dante2/package/es/components/popovers/addButton.js'
import {DanteTooltipConfig} from 'Dante2/package/es/components/popovers/toolTip.js' //'Dante2/package/es/components/popovers/toolTip.js'
import {ImageBlockConfig} from 'Dante2/package/es/components/blocks/image.js'
import {EmbedBlockConfig} from 'Dante2/package/es/components/blocks/embed.js'
import {VideoBlockConfig} from 'Dante2/package/es/components/blocks/video.js'
import {PlaceholderBlockConfig} from 'Dante2/package/es/components/blocks/placeholder.js'
import {VideoRecorderBlockConfig} from 'Dante2/package/es/components/blocks/videoRecorder'
import {CodeBlockConfig} from 'Dante2/package/es/components/blocks/code'
import {HeadingTwoBlockConfig} from './editor/headingTwo.js'

import config from "../constants/config"
import ReadingProgress from './progress'
import {flatten} from "lodash"

import lookFor from '../images/look-for.png'

import Cover from './cover'

import {
  Link
} from 'react-router-dom'

import {
  isEmpty
} from 'lodash';

import styled from '@emotion/styled'

const TocHeading = styled.div`

  display:none;

  @media only screen and (min-width: 1280px){
    position: fixed;
    display:block;
    right: ${props => props.open ? "0px" : "-135px"};
    height: auto;
    top: 0px;
    bottom: 0px;
    padding: 20px;
    background: #fffbfbe8;
    border-left: 1px solid #e4dede;
    width: 186px;
    box-shadow: 2px -1px 9px 0px #ccc;

    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;

    z-index: 9;
    ul{
      width: 201px;
      margin: 0px;
      padding: 9px 0px;
      list-style: none;
    }
    h3{
      font-size: 1em;
      color: #8c8782;
    }
    a{
      text-decoration: underline;
      font-size: 0.98em;
      width: 170px;
      display: block;
      margin-bottom: 10px;
      padding-right: 4px;
    }
  }
`
export default class PublicArticle extends React.Component {

  constructor(props){
    super(props)
    this.renderEditor = this.renderEditor.bind(this)
    this.endpoint     = this.endpoint.bind(this)
    this.defaultUUID = uuidV4() 

    this.enpointRef = null
    this.summariesRef = null
    this.current_ID = null

    this.state =  {
      article: null,
      loading: true,
      endpointKey: null,
      visibleSummary: false,
      tocHeadings: [],
      tocOpen: true
    }
  }

  endpoint(){
    let url = this.state.endpointKey 
    return '/articles/' + url
  }

  componentDidMount(){
    return this.resetAndFetch()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.authenticated != prevProps.authenticated )
      return this.resetAndFetch()

    if(prevProps.match.params.article_id !== this.props.match.params.article_id){
      
      this.setState({loading:true}, ()=>{

        this.props.getArticle(this.props.match.params.id, 
          this.props.match.params.article_id, 
          {success: ()=> { 
              //console.log("article about to change")
              this.setState({loading: false}) 
              this.renderTocHeadings()
            }
          }
        )

      })
    }

  }

  resetAndFetch = ()=>{
    this.props.resetBook()
    console.log(this.props.currentUser)
    this.props.onGetBook(
      this.props.match.params.id, 
      {
        success: (book)=>{
          if(!book)
            this.setState({loading: false})

          this.props.getArticle(this.props.match.params.id, 
            this.props.match.params.article_id, 
            {
              success: ()=> { 
                this.setState({loading: false})
                this.renderTocHeadings()
              }
            })
        },
        error: (err)=> { this.setState({loading: false}) }
      })

  }

  renderTocHeadings = ()=>{
    if(!isEmpty(this.props.article)){
      const content = JSON.parse(this.props.article.content)
      const tocHeadings = content.blocks.filter((b)=> (b.type === "header-two"))
      this.setState({tocHeadings: tocHeadings })
    }
  }

  renderTreeItem = (item, i)=>{
    return <li className="item" key={i} index={i}>
                          <a className="active" href="#" onClick={(e)=> {
                              e.preventDefault()
                              this.setState({visibleSummary: !this.state.visibleSummary })
                              const url = "/public/books/"+ this.props.match.params.id + "/article/" + item.slug
                              this.props.history.push(url);
                              // to={"/public/books/"+ slug(this.props.book.slug) + "/article/" + item.id }
                            }
                          }>
                            {item.title}
                          </a>
                        </li>
  }

  renderTree = ()=>{

   
    if(!this.props.book){
      return null
    }

    return <div className="sections">
              <h3>Sections:</h3>
              <ul>
                { this.props.book.treeData.map((item, i)=>{
                  return [this.renderTreeItem(item, i), 
                  <ul> 
                    {item.children.map((o)=> this.renderTreeItem(o, i) ) }
                  </ul> ]
                  })
                }
              </ul>
            </div>

  }

  widgetsConfig = ()=>{
    return [ HeadingTwoBlockConfig(),
            CodeBlockConfig(),
            ImageBlockConfig({
                options: {
                  upload_url: `${config.endpoint}/uploads?book_id=${this.props.book.slug}&article_id=${this.props.article.slug}`,
                  //upload_handler: this.handleUpload,
                  image_caption_placeholder: "type a caption (optional)"
                }
            }), 
             EmbedBlockConfig({ options: {
                                      placeholder: "put an external linkss",
                                      endpoint: `${config.endpoint}/oembed?url=`
                                    } 
                              }),
             VideoBlockConfig({ breakOnContinuous: true,
                                options: {
                                    placeholder: "put an external video link",
                                    endpoint: `${config.endpoint}/oembed?url=`,
                                    caption: 'optional caption'
                                  } 
                                }),
             PlaceholderBlockConfig(),
             VideoRecorderBlockConfig({
                options: {
                  seconds_to_record: 20000,
                  upload_url: `${config.endpoint}/uploads?book_id=${this.props.book.slug}&article_id=${this.props.article.slug}`,
                }
             })
           ]
  }

  renderEditor(){
    // !this.state.loading &&
    if (!isEmpty(this.props.article) && !this.state.loading ){
      return <Dante
        read_only={true}
        //onChange={(e)=>{debugger}}
        widgets={this.widgetsConfig()}
        content={JSON.parse(this.props.article.content)}
        //key={this.props.location.key}
        config={ 
          {
            debug: false, 
            read_only: true,
            api_key: "86c28a410a104c8bb58848733c82f840"
          } 
        } 
      />
    } else {
      return <Loader/>
    }                         
  }

  renderIndexBase = ()=>{
    if(!this.props.book){
      return null
    }

    return <div className="sections">
              <h3>Sections:</h3>
              <ul>
                { this.props.book.treeData.map((item, i)=>{
                  return <li className="item" key={i} index={i}>
                          <a className="active" href="#" onClick={(e)=> {
                              e.preventDefault()
                              this.setState({visibleSummary: !this.state.visibleSummary })
                              const url = "/public/books/"+ this.props.match.params.id + "/article/" + item.slug
                              this.props.history.push(url);
                              // to={"/public/books/"+ slug(this.props.book.slug) + "/article/" + item.id }
                            }
                          }>
                            {item.title}
                          </a>
                        </li>
                  })
                }
              </ul>
            </div>
  }

  renderIndexTrees(){
    if(!this.props.book.articles && !this.props.article){
      return null
    }

    let childs = []

    // first level
    let activeToc = this.props.book.treeData.find((o)=> 
      this.props.article.slug === o.slug
    )

    if(activeToc)
      childs = activeToc.children


    // search for children
    if(!activeToc){
      activeToc = flatten(this.props.book.treeData
        .map((o)=> o.children))
        .find((o)=> o.slug === this.props.article.slug)

      childs = this.props.book.treeData.find((o)=> o.slug === activeToc.parent ).children
        
    }

    if(!activeToc){
      return null
    }
    
    return childs.length > 0 ? 
                <div className="chapters">
                  <h3>chapters</h3>

                  <ul>
                     { childs.map((item, i)=>(
                          <li className="item" key={i} index={i}>
                            <a className="active" href="#" onClick={(e)=> {
                                e.preventDefault()
                                this.setState({visibleSummary: !this.state.visibleSummary })
                                const url = "/public/books/"+ this.props.match.params.id + "/article/" + item.slug
                                this.props.history.push(url);
                                // to={"/public/books/"+ slug(this.props.book.slug) + "/article/" + item.id }
                              }
                            }>
                              {item.title}
                            </a>
                          </li>
                      ))
                    }
                  </ul>
                  <p>
                    8 chapters online, 
                    25 in the full book
                  </p>
                </div> : null
  }

  render404(){
    return (
            <Cover 
              title={"Article not found"} 
              description="The link is broken or the page has been moved">

              <p> Go back to 
                <Link to={"/"} > Home </Link> 

                {
                  this.props.book ?
                  <React.Fragment>
                    or {" "} 
                    <Link to={`/books/${this.props.book.slug}`} > 
                    Edit this book 
                    </Link>
                    {" "}
                    if you own it 
                  </React.Fragment> : null
                }

              </p>

              <img src={lookFor} />
            </Cover>
           )
  }


  renderCarousel = ()=>{

    const next = this.props.article.next
    const prev = this.props.article.prev

    return <nav className="carousel js-carousel">
      
      {
        next ?
        <Link to={"/public/books/"+ this.props.match.params.id + "/article/" + next.slug }
            className="next">
            <span className="title">{next.title}</span>
            <span className="label">Next</span>
            <svg width="12px" height="22px" viewBox="0 0 12 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <g transform="translate(-40.000000, -91.000000)">
                <path d="M50.5117343,91.8578644 L51.8578644,93.2720779 L43.5501245,102 L51.8578644,110.727922 L50.5117343,112.142136 L40.8578644,102 L41.5309294,101.292893 L50.5117343,91.8578644 Z" transform="translate(46.357864, 102.000000) rotate(-180.000000) translate(-46.357864, -102.000000)" />
              </g>
            </svg>
        </Link> : null
      }
      

      {
        prev ? <Link 
        to={"/public/books/"+ this.props.match.params.id + "/article/" + prev.slug }
        className="previous">
        <svg width="12px" height="22px" viewBox="0 0 12 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g transform="translate(-19.000000, -91.000000)">
            <path d="M29.5117343,91.8578644 L30.8578644,93.2720779 L22.5501245,102 L30.8578644,110.727922 L29.5117343,112.142136 L19.8578644,102 L20.5309294,101.292893 L29.5117343,91.8578644 Z" />
          </g>
        </svg> 
        <span className="title">{prev.title}</span>
        <span className="label">Previous</span>
      </Link> : null
      }

    </nav>

  }

  render(){

    const Wrapper = posed.div({
      exit: { opacity: 0,  display: 'none', delay: 0 },
      enter: { opacity: 1, display: 'block', delay: 1000 },
      transition: {
        duration: 400
      }
    });

    const Background = posed.div({
        enter: { 
          x: 0, 
          opacity: 1, 
          delay: 0, 
          display: 'block',
          transition: { 
            duration: 500
          }
        },
        exit: {
          x: -1150,
          opacity: 1,
          display: 'block',
          delay: 300,
          transition: { 
            duration: 500
          }
        }
    });

    const isVisible = this.state.visibleSummary

    return (

      <React.Fragment>

      {
        this.props.book && this.props.article ? 
          <div className="container-fluid">
            
            <div className="row">

                <div className={`toc is-section-beginning ${isVisible ? 'is-expanded' : ''}`}>
                <header>
                  
                  <h2><span> {this.props.article.title} </span></h2>
                  <a href="#" className="icon" onClick={(e)=> this.setState({ 
                    visibleSummary: !this.state.visibleSummary })
                  }>
                  {this.state.visibleSummary ? 'X' : '|||'}
                  </a>
                </header>


                <PoseGroup>
                  { isVisible && [
                    <Background 
                      key={'background'} 
                      className="background" 
                      initialPose="exit" 
                      pose="enter"
                    />,
                    <Wrapper key={'wrapper'} className="wrapper">
                      
                      {/*this.renderIndexBase()*/}

                      {/*this.renderIndexTrees()*/}

                      {this.renderTree()}

                    </Wrapper>
                  ]}
                </PoseGroup>

              </div>
              
              <div className="content-wrapper">

                <header className="bookHeader">
                  <h1 className="text">
                    <a>{this.props.book.title}</a>
                  </h1>

                  <ul className="Booknav">
                    {/*<li><a>about the book</a></li>
                    <li><a>about the author</a></li>
                    <li>
                      <Link to={"/books/"+ this.props.book.slug }>
                        <i className="fa fa-pen-alt"/>
                        {" "}
                        edit book
                      </Link>
                    </li>*/}
                  </ul>
                </header>

                <div className="content">
                  {this.renderEditor()}                      
                </div>

                {
                  !isEmpty(this.props.article) && !this.state.loading ?
                  <ReadingProgress targetEl=".content" /> : null 
                }
                

                {
                  this.state.tocHeadings.length > 0 ?
                
                    <TocHeading open={this.state.tocOpen}>
                      
                      <h3>
                        <button onClick={(e)=>{
                            e.preventDefault()
                            this.setState({
                              tocOpen: !this.state.tocOpen
                            })
                          }}>
                          <i className={`fa fa-angle-${this.state.tocOpen ? 'right' : 'left'}`}/>
                          </button>
                        {" "}
                        Table of contents
                      </h3>

                      <ul style={{width: '201px'}}>
                        {
                          this.state.tocHeadings.map((o)=>{
                            return <li>
                                      <a href={`#${o.key}`}>
                                        {o.text}
                                      </a>
                                    </li>
                          })
                        }
                      </ul>
                    </TocHeading> : null
                }

                {this.renderCarousel()}
              </div>

            </div> 
        
          </div>
        : this.state.loading ? <Loader/> : this.render404()
      }

      <footer>
        <p>
          
          <Link className="hallmark" to={"/"}>
            powered by proseflow
          </Link>
        </p>
      </footer>

      </React.Fragment>


    )
  }
}




