import React, {Component} from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/lib/Async';
import graphql from '../graphql/client'
import {isEmpty} from 'lodash'
import {
  ADD_USER_TO_BOOK
} from '../graphql/mutations'

import {
  INVITED_USERS_BOOK, USER_SEARCH
} from '../graphql/queries'


export default class UserConfigForm extends Component {

  state = {
    users: [],
    userSearch: [],
    inputValue: "",
    selectedOption: {}
  }

  componentDidMount(){
    this.getUsers()
  }

  getUsers = ()=>{
    graphql(INVITED_USERS_BOOK, {id: this.props.book.slug},{
      success: (data)=>{
        this.setState({
          users: data.userBook.users
        })
      },
      error: ()=>{

      }
    })
  }

  searchUser = (inputValue, callback)=>{

    graphql(USER_SEARCH, {
      query: this.state.inputValue,
      bookId: this.props.book.slug
    }, {
      success: (data)=>{
        this.setState({
          userSearch: data.userSearch
        }, ()=>{
          callback( 
            this.state.userSearch.map((o)=>({ value: o.email, label: o.email })) 
          )
        })
      },
      error: ()=>{

      }
    })
  }

  handleInputChange = (newValue: string) => {
    const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue });
    return inputValue;
  }

  loadOptions = (inputValue, callback) => {
    this.searchUser(inputValue, callback)
  }

  addUserToBook = ()=>{
    graphql(ADD_USER_TO_BOOK, {
      bookId: this.props.book.slug, 
      email: this.state.selectedOption.value }, {
      success: (data)=>{
        this.getUsers()
      },
      error: ()=>{}
    })
  }

  enableSubmit = (e)=>{
    console.log("e", e)
    this.setState({
      selectedOption: e
    })
  }


  render(){
    return <div className="p-5 pt-0">
      <div className="form-group">

        <AsyncSelect 
          ref="select"
          onInputChange={this.handleInputChange}
          loadOptions={this.loadOptions}
          placeholder={"search by email"}
          onChange={this.enableSubmit}
        />
        <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
      </div>

      {
        !isEmpty(this.state.selectedOption) ?
          <button type="submit" 
            className="btn btn-primary" 
            onClick={this.addUserToBook}>
            add collaborator
          </button> : null 
      }


      <table className="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">email</th>
          {/*<th scope="col">status</th>
            <th scope="col">actions</th>
          */}
        </tr>
      </thead>
      <tbody>
        {
          this.state.users.map((o,i)=>{
            return  <tr>
                      <th scope="row">{i}</th>
                      <td>{o.email}</td>
                      {/*<td>pending</td>
                      <td>resend</td>*/}
                    </tr>
          })
        }
      </tbody>
    </table>
    </div>
  }


}