import { combineReducers } from 'redux'
//import { routerReducer as routing } from 'react-router-redux'

import {articleReducer} from './article.js'
import {userReducer} from './user.js'
import {bookReducer} from './book.js'
import {booksReducer} from './books.js'

const rootReducer = combineReducers({
  articleReducer, userReducer, bookReducer, booksReducer
})

export default rootReducer