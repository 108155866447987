import React,{Component, Fragment} from 'react'

import {
  Route,
  Link,
  BrowserRouter as Router,
} from 'react-router-dom'


import {
        PrivatePropsRoute,
        PropsRoute
       } from './router_utils.js'

import config from "../constants/config"
import serialize from 'form-serialize'
import axios from 'axios'

export default class BookConfig extends Component{

  state = {
    section: 1
  }


  render(){
    return(
      <div>


        <ul className="nav nav-tabs pl-3">
          
          <li className="nav-item pr-1">
            <Link className="nav-link active" 
              to={this.props.match.url}>Book Settings
            </Link>
          </li>

          <li className="nav-item pr-1">

            <Link className="nav-link active" 
              to={this.props.match.url + "/hosting"}>Hosting
            </Link>

          </li>

          <li className="nav-item pr-1">
            <Link className="nav-link active" 
              to={this.props.match.url + "/appearance"}>Appearance
            </Link>
          </li>

        </ul>

        <div className="p-md-5 pt-0">

          <PrivatePropsRoute exact 
            path={`${this.props.match.path}`} 
            component={Settings}
            authenticated={this.props.authenticated}
            currentUser={this.props.currentUser}
            book={this.props.book}
            onGetBook={this.props.onGetBook}
            updateBook={this.props.updateBook}
            exportDoc={this.props.exportDoc}
          />

          <PrivatePropsRoute exact 
            path={`${this.props.match.path}/settings`} 
            component={Settings}
            authenticated={this.props.authenticated}
            currentUser={this.props.currentUser}
            book={this.props.book}
            onGetBook={this.props.onGetBook}
            updateBook={this.props.updateBook}
            exportDoc={this.props.exportDoc}
          />

          <PrivatePropsRoute exact 
            path={`${this.props.match.path}/hosting`} 
            component={Hosting}
            authenticated={this.props.authenticated}
            currentUser={this.props.currentUser}
            book={this.props.book}
            onGetBook={this.props.onGetBook}
          />

          <PrivatePropsRoute exact 
            path={`${this.props.match.path}/appearance`} 
            component={Appearance}
            authenticated={this.props.authenticated}
            currentUser={this.props.currentUser}
            book={this.props.book}
            onGetBook={this.props.onGetBook}
          />
        </div>

      </div>
    )
  }
}




class Settings extends Component {

  constructor(props){
    super(props)
    this.updateBook = this.updateBook.bind(this)
    this.state = {
      loading: false
    }
  }

  updateBook(e){
    e.preventDefault()
    /*let opts = {
      bookId: this.props.book.slug,
      title: this.refs.book_title.value,
      description: this.refs.book_summary.value,
      public: this.refs.privateCheck.checked
    }*/

    let data = serialize(this.refs.book_form, { hash: true, empty: true });
    data.bookId = this.props.book.slug
    data.public = data.public != ""
    console.log(data)
    this.props.updateBook(data)
  }

  upload = ()=>{
    var formData = new FormData();
    var imagefile = this.refs.book_cover;

    let url = `${config.endpoint}/book_cover?book_id=${this.props.book.slug}`

    formData.append("image", imagefile.files[0]);
    this.setState({
      loading: true
    })

    axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then( r => {
      this.setState({
        loading: false
      })

      this.props.onGetBook(this.props.book.slug)
    })

  }

  image = ()=>{

     if(this.state.loading)
      return "https://loading.io/spinners/ball/lg.bouncing-circle-loading-icon.gif"

    return this.props.book.image ? 
              this.props.book.image : 
              "https://via.placeholder.com/600"
  }

  handleExport = (e)=>{
    e.preventDefault()
    this.props.exportDoc(this.props.book)
  }

  render(){
    return <div className="p-md-5">

        <h2>Book settings</h2>

        <button onClick={this.handleExport} >export to docx</button>

        <div className="row">
          <div className="col-sm-9">
            <form ref="book_form"
              action={`/`} 
              method="post"  
              onSubmit={this.updateBook}>
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">
                  Book Title
                </label>
                
                <input 
                  type="text" 
                  className="form-control"
                  name="title" 
                  id="exampleInputEmail1" 
                  aria-describedby="emailHelp" 
                  placeholder="Enter article title"
                  ref="article_title"
                  defaultValue={this.props.book.title}
                />

                <small id="emailHelp" className="form-text text-muted">
                  Write the book's title.
                </small>
              </div>

              <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">Summary</label>
                  <textarea className="form-control" 
                    rows="3" 
                    ref="textArea"
                    name="description" 
                    defaultValue={this.props.book.description}>
                  </textarea>
              </div>

              <div className="form-check">
                <label className="form-check-label">
                  <input type="checkbox" 
                    ref="privateCheck"
                    name="public" 
                    className="form-check-input"
                    defaultChecked={this.props.book.public}/>
                  Keep this book public
                </label>
              </div>

              <button type="submit" 
                className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>

          <div className="col-sm-3">

            <img 
              src={this.image()} 
              className="img-fluid" 
              alt="Responsive image"
            />

            {
              this.state.loading ?
                <form>
                  <input 
                    type="file"
                    className="form-control" 
                    ref="book_cover" 
                    onChange={this.upload}
                  />
                </form> : null
            }
            
          </div>
        </div>

    </div>
  }
}


class Appearance extends Component {
  render(){
    return <div className="p-5">

        <h2>Appearance</h2>

        <form>
          <div className="form-group">
            
            <label htmlFor="exampleInputEmail1">
              Custom Domain
            </label>
            
            <input 
              type="text" 
              className="form-control" 
              id="exampleInputEmail1" 
              aria-describedby="emailHelp" 
              placeholder="enter domain"
              ref="article_title"
            />
            <small id="emailHelp" className="form-text text-muted">
              enter domain name
            </small>
          </div>

          <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">custom css</label>
              <textarea className="form-control" rows="3" ref="textArea">
              </textarea>
          </div>

        </form>
    
    </div>
  }
}


class Hosting extends Component {
  render(){
    return <div className="p-5">
      <h2>Hosting settings</h2>
      <form>
        <div className="form-group">
          
          <label htmlFor="exampleInputEmail1">
            Custom Domain
          </label>
          
          <input 
            type="text" 
            className="form-control" 
            id="exampleInputEmail1" 
            aria-describedby="emailHelp" 
            placeholder="enter domain"
            ref="article_title"
          />
          <small id="emailHelp" className="form-text text-muted">
            enter domain name
          </small>
        </div>

      </form>
    </div>
  }
}




