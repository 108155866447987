import React from 'react'
import Menu from './menu.js'
import Home from './home.js'
import ArticleEditor from './article_editor.js'
import Books from "./books.js";
import PublicArticle from './public_book.js'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from "../constants/config"

import actioncable from "actioncable"

import Prism from 'prismjs';
import Normalizer from 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace'
import '../styles/prism.css'

import * as firebase from 'firebase'

import ScrollToTop from './router_scroll'

import {
  //HashRouter,
  BrowserRouter as Router,
  //Route,
  //Link,
  //Switch,
  //Redirect,
} from 'react-router-dom'

import {
        PrivatePropsRoute,
        PropsRoute
       } from './router_utils.js'

const ChannelApp = {
  cable: actioncable.createConsumer(`${config.wsEndpoint}/cable`)
};

window.ChannelApp = ChannelApp

console.log("sockete")

export default class App extends React.Component {
  constructor(props){
    super(props)
    this.handleLogin = this.handleLogin.bind(this)
  }

  componentDidMount(){
    this.props.onCheckCurrentUser()
  }



  handleLogin(e){
    e.preventDefault()
    this.props.onLogin()
  }

  renderPrivateMenu(){
    return <Menu authenticated={this.props.authenticated}
                    currentUser={this.props.currentUser}
                    onSignOut={this.props.onSignOut}
    />
  }

  renderPublicMenu(){
    return <Menu authenticated={this.props.authenticated}
                    currentUser={this.props.currentUser}
                    onSignOut={this.props.onSignOut}
    />
  }

  renderMenu = ()=>{
    return this.props.authenticated ? this.renderPrivateMenu() : this.renderPublicMenu()           
  }

  render(){
    return (
      <Router>
        <ScrollToTop>
          <div className="d-flex">

            <ToastContainer />

            <PropsRoute exact path='/' 
                component={Home} 
                authenticated={this.props.authenticated}
                currentUser={this.props.currentUser}
                f={firebase} 
                key="1"
                handleLogin={this.handleLogin}
                onAddBook={this.props.onAddBook}
                renderMenu={this.renderMenu}
                ChannelApp={ChannelApp}
            />

            <PrivatePropsRoute exact path='/books' 
              handleLogin={this.handleLogin}
              currentUser={this.props.currentUser}
              authenticated={this.props.authenticated}
              component={Books} 
              books={this.props.books}
              removeBook={this.props.removeBook}
              getBooks={this.props.getUserBooks}
              onAddBook={this.props.onAddBook}
              renderMenu={this.renderMenu}
              ChannelApp={ChannelApp}
            />

            <PrivatePropsRoute path='/books/:id' 
              handleLogin={this.handleLogin}
              authenticated={this.props.authenticated}
              currentUser={this.props.currentUser}
              component={ArticleEditor} 
              onAddBook={this.props.onAddBook}
              onGetBook={this.props.onGetUserBook}
              exportDoc={this.props.exportDoc}
              updateBook={this.props.updateBook}
              resetBook={this.props.resetBook}
              book={this.props.book}
              article={this.props.article}
              summary={this.props.summary}
              getArticle={this.props.getUserArticle}
              newChapter={this.props.newChapter}
              changeTitle={this.props.changeTitle}
              removeChapter={this.props.removeChapter}
              getFirstArticle={this.props.getFirstArticle}
              updateArticle={this.props.updateArticle}
              updateTreeData={this.props.updateTreeData}
              addFile={this.props.addFile}
              renderMenu={this.renderMenu}
              ChannelApp={ChannelApp}
              f={firebase} 
            />

            <PropsRoute exact path='/public/books/:id' 
              handleLogin={this.handleLogin}
              currentUser={this.props.currentUser}
              authenticated={this.props.authenticated}
              component={PublicArticle} 
              onAddBook={this.props.onAddBook}
              onGetBook={this.props.onGetBook}
              resetBook={this.props.resetBook}
              book={this.props.book}
              article={this.props.article}
              summary={this.props.summary}
              getArticle={this.props.getArticle}
              newChapter={this.props.newChapter}
              changeTitle={this.props.changeTitle}
              removeChapter={this.props.removeChapter}
              getFirstArticle={this.props.getFirstArticle}
              updateArticle={this.props.updateArticle}
              addFile={this.props.addFile}
              f={firebase} 
            />

            <PropsRoute exact path='/public/books/:id/article/:article_id' 
              handleLogin={this.handleLogin}
              currentUser={this.props.currentUser}
              authenticated={this.props.authenticated}
              component={PublicArticle} 
              onAddBook={this.props.onAddBook}
              onGetBook={this.props.onGetBook}
              resetBook={this.props.resetBook}
              book={this.props.book}
              article={this.props.article}
              summary={this.props.summary}
              getArticle={this.props.getArticle}
              newChapter={this.props.newChapter}
              changeTitle={this.props.changeTitle}
              removeChapter={this.props.removeChapter}
              getFirstArticle={this.props.getFirstArticle}
              updateArticle={this.props.updateArticle}
              addFile={this.props.addFile}
              f={firebase} 
            />
          </div>
        </ScrollToTop>
      </Router>
    )
  }
}
