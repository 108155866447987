import axios from 'axios';
import _ from 'lodash'
import config from "../constants/config"

const graphql = (query, variables, callbacks)=>{
  // TODO: how to access this without call window?
  const user = window.store.getState().userReducer.currentUser ? 
  window.store.getState().userReducer.currentUser : {}

  axios.create({
    baseURL: `${config.endpoint}/graphql`,
    headers: {
      uid: user.token,
      email: user.email
    }
  }).post('', {
    query: query,
    variables: variables
  })
  .then( r => {
    const data = r.data.data
    const res = r

    const errors = r.data.errors ? r.data.errors[0].messages : null
    // get first key of data and check if has errors
    //const errors = data[Object.keys(data)[0]].errors || r.data.errors
    if (_.isObject(errors) && !_.isEmpty(errors)) {
      
      //const errors = data[Object.keys(data)[0]];
      //callbacks['error'] ? callbacks['error'](res, errors['errors']) : null
      if(callbacks['error'])
        return callbacks['error'](res, errors)
    }

    callbacks['success'] ? callbacks['success'](data, res) : null // eslint-disable-line no-unused-expressions
  })
  .catch( r => {
    const res = r.response
    callbacks['error'] ? callbacks['error'](res) : null // eslint-disable-line no-unused-expressions
  })
  .then( () => {
    callbacks['always'] ? callbacks['always']() : null // eslint-disable-line no-unused-expressions
  });
}

export default graphql
