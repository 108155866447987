import React from 'react'
import UserMenu from './userMenu'
import {
  Link
} from "react-router-dom"


export default class Menu extends React.Component {

  constructor(props){
    super(props)
    this.handleLogout = this.handleLogout.bind(this)
  }

  handleLogout(e){
    e.preventDefault()
    this.props.onSignOut()
  }

  render(){

    return (

      <nav id="main-nav" className="navbar navbar-expand-lg navbar-light">
        <Link to="/" className="navbar-brand">ProseFlow</Link>
        
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <Link to="/books" className="nav-link">Books</Link>
            </li>
          </ul>

          <UserMenu {...this.props} handleLogout={this.handleLogout}/>
        </div>
      </nav>

    )
  }
}