export const ADD_BOOK = `
  mutation AddBook($title: String!, $description: String!, $public: Boolean!){
    addBook(title: $title, description: $description, public: $public){
      result
      errors
      book {
        title
        slug
        description
      }
    }
  }
`;

export const ADD_USER_TO_BOOK = `
  mutation AddUserToBook($bookId: String!, $email: String!){
    addUserToBook(bookId: $bookId, email: $email){
      result
      errors
    }
  }
`;

export const UPLOAD_IMAGE = `

  mutation UploadImage($bookId: String!, $articleId: String!, $file: String!){
    uploadImage(bookId: $bookId, articleId: $articleId, file: $file){
      result
      errors
    }
  }
`;

export const GENERATE_WORD = `
  mutation GenerateWord($bookId: String!){
    generateWord(bookId: $bookId){
      result
      errors
    }
  }
`

//$file: Upload

export const EDIT_BOOK = `
  mutation EditBook($bookId: String!,
                    $title: String!,
                    $description: String!,
                    $public: Boolean!){
      editBook( bookId: $bookId,
                title: $title,
                description:  $description, 
                public:  $public){
        result
        errors

        book {
          title
          slug
          description
          public
          password
          image
          treeData
        }


      }
    }
`;

export const REMOVE_ARTICLE = `
  mutation RemoveArticle($bookId: String!, $articleId: String!){
    removeArticle(bookId: $bookId, articleId: $articleId ){
      result
      errors
      book{
          title
          slug
          description
          public
          password
          image
          treeData
      }
    }
  }
`

export const ADD_ARTICLE = `
  mutation AddArticle($bookId: String!, $title: String!, $content: String!, $public: Boolean!){
    addArticle(bookId: $bookId, title: $title, content: $content, public: $public){
      result
      errors
      article {
        title
        slug
        content
        public
      }
      book {
          title
          slug
          description
          public
          password
          image
          treeData
      }
    }
  }
`

export const UPDATE_ARTICLE = `


  mutation EditArticle( $bookId: String!,
                        $articleId: String!,
                        $title: String!,
                        $content: String,
                        $public: Boolean! ){
    editArticle( bookId: $bookId, 
    articleId: $articleId, 
    title: $title , 
    content: $content , 
    public: $public ){
      article {
        title
        slug
        content
        public
      }
    }
  }

`

export const REMOVE_BOOK = `
  mutation RemoveBook($id: String!){
    removeBook(id: $id ){
      result
      errors
    }
  }
`

export const UPDATE_BOOK_TREE = `
  mutation UpdateBookTree($id: String!, $tree: [Json!]!){
    updateBookTree(id: $id, tree: $tree){
      result
      errors
    }
  }
`