import ActionTypes from '../constants/action_types';
import database from './database';
import {auth, provider} from './database';

//provider.addScope('https://www.googleapis.com/auth/contacts.readonly');


export function checkCurrentUser(){
  return dispatch => {
    auth.onAuthStateChanged((result)=>{
      

      if (result) {
        let user = {
          email: result.email, 
          avatar: result.photoURL, 
          token: result.uid,
          name: result.displayName
        }
        dispatch(setCurrentUser(user))
        //this.setState({authenticated: true, currentUser: juser})
        // User is signed in.
      } else {
        dispatch(setSignOut())
        //this.setState({authenticated: false, currentUser: null})
        // No user is signed in.
      }
    });
  }
}

export function handleLogin(){

  return dispatch => {
    auth.signInWithPopup(provider).then((result)=> {
      // This gives you a Google Access Token. You can use it to access the Google API.
      let user = {
          email: result.user.email, 
          avatar: result.user.photoURL,
          token: result.user.uid,
          name: result.user.displayName
        }
      dispatch(setCurrentUser(user))
      // ...
    }).catch((error)=> {
      
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
      //dispatch(setCurrentUser(errorMessage))
    });
  }
}

export function signOut(){
  return dispatch => {
    auth.signOut().then(() =>{
      // Sign-out successful.
      //this.setState({authenticated: false})
      dispatch(setSignOut())

    }).catch(function(error) {
      // An error happened.
    });
  }
}


function setCurrentUser(user) {
  return {
    type: ActionTypes.setCurrentUser,
    data: user
  };
}

function setSignOut() {
  return {
    type: ActionTypes.setSignOut
  }
}