import PouchDB from 'pouchdb'
import pouchdbfind from 'pouchdb-find'

PouchDB.plugin(pouchdbfind);

const url = 'https://fa45cc0a-9a12-498b-b36d-85e58ddf7013-bluemix.cloudant.com/test-database'

const dbName = 'myDB'

const pouch = new PouchDB(url, { options: {auth: {
    username: "mstimpectiontoresservies" ,
    password: "baa5a8735d77e6bcad749422bfceed283bd3ca34"
  }}
});

console.log(pouch.adapter); // prints 'idb'

pouch.createIndex({
  index: {fields: ['name', 'book_id', 'public']}
});

export default pouch