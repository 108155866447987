import React, {Component} from 'react'
import {
  isEmpty
} from 'lodash';
import config from "../constants/config"

import { 
  CompositeDecorator, 
  EditorState , 
  convertToRaw, 
  convertFromRaw , 
  createEditorState,
  getVisibleSelectionRect,
  SelectionState
} from 'draft-js'

import MultiDecorator from 'draft-js-multidecorators'

import Dante from "Dante2"
import {DanteImagePopoverConfig} from 'Dante2/package/es/components/popovers/image.js'
import {DanteAnchorPopoverConfig} from 'Dante2/package/es/components/popovers/link.js'
import {DanteInlineTooltipConfig} from 'Dante2/package/es/components/popovers/addButton.js' //'Dante2/package/es/components/popovers/addButton.js'
import {DanteTooltipConfig} from 'Dante2/package/es/components/popovers/toolTip.js' //'Dante2/package/es/components/popovers/toolTip.js'
import {ImageBlockConfig} from './article/image.js'
import {EmbedBlockConfig} from 'Dante2/package/es/components/blocks/embed.js'
import {VideoBlockConfig} from 'Dante2/package/es/components/blocks/video.js'
import {PlaceholderBlockConfig} from 'Dante2/package/es/components/blocks/placeholder.js'
import {VideoRecorderBlockConfig} from 'Dante2/package/es/components/blocks/videoRecorder'
import {CodeBlockConfig} from 'Dante2/package/es/components/blocks/code'
import {DividerBlockConfig} from "Dante2/package/es/components/blocks/divider";

import Prism from 'prismjs';
import {PrismDraftDecorator} from 'Dante2/package/es/components/decorators/prism'

import {GiphyBlockConfig} from './article/giphyBlock'
import {SpeechToTextBlockConfig} from './article/speechToTextBlock'
import {DanteMarkdownConfig} from './article/markdown'

import Link from 'Dante2/package/es/components/decorators/link'
import findEntities from 'Dante2/package/es/utils/find_entities'

import Loader from './loader'

import jsondiff from "json0-ot-diff"
import ot from 'ot-json0'

import {ArticlePad} from './styledBlocks'
import RtcView from './rtc'
import styled from '@emotion/styled'

const findWithRegex = (regex, contentBlock, callback) => {
  const text = contentBlock.getText();
  let matchArr, start, end;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    end = start + matchArr[0].length;
    callback(start, end);
  }
};

const findSelectedBlockFromRemote = (selectionRange, contentBlock, callback) => {
  const text = contentBlock.getText();
  const k = contentBlock.getKey();
  let sel, start, end;

  sel = selectionRange.find((o)=> o.anchorKey === k)
  console.log("selection: ", selectionRange, k )
  if(sel){
    start = sel.anchorOffset
    end = start === sel.focusOffset ? sel.focusOffset + 1 : sel.focusOffset 
    console.log("entity at", start, end)
    callback(start, end)
  }

};

export const SelectionIndicator = styled.span`
  position: relative;
  background-color: red;
  }
`
const UserIndicator = styled.div`
  padding: 2px;
  position: absolute;
  bottom: -28px;
  right: 0px;
  display: inline-block;
  background: #00000094;
  color: white;
  border-radius: 3px;
  padding-left: 6px;
  padding-right: 6px;
  border: 1px solid #000;
  z-index: 2;
  font-size: 0.7rem;
  i.arrow{
    &:after{
     content: &#9658;
    }
  }
`

export default class Article extends Component {

  constructor(props){
    super(props)

    this.saveHandler = this.saveHandler.bind(this)
    this.dante_editor = null
    this.ChannelEvents = null
    this.conn = null
    this.menuResizeFunc = null
    this.state =  {
      loading: true,
      currentContent: null,
      diff: "",
      videoSession: false,
      selectionPosition: null,
      incomingSelectionPosition: []
    }
  }

  componentDidMount(){
    this.props.getArticle(this.props.book, 
      this.props.match.params.article_id, 
      { success: ()=> { 
        this.setState({loading: false})
      }}
    )

    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount(){
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.match.params.article_id !== this.props.match.params.article_id){
      
      this.setState({loading:true}, ()=>{

        this.props.getArticle(this.props.book, 
          this.props.match.params.article_id, 
          {
            success: ()=> { 
              console.log("article about to change")
              this.setState({loading: false}) 
            }
          }
        )

      })
    }

    if(prevState.currentContent && prevState.currentContent != this.state.currentContent)
      this.handleChangesOnCurrentContent(prevState.currentContent, this.state.currentContent)
  }

  tooltipsConfig = ()=>{
    return [ DanteImagePopoverConfig(),
             DanteAnchorPopoverConfig(),
             DanteInlineTooltipConfig(),
             DanteTooltipConfig(),
             //DanteMarkdownConfig()
            ]
  }

  decorators = (context)=>{
    return (context)=> {
      return new MultiDecorator([
        PrismDraftDecorator({
          prism: Prism,
          defaultSyntax: 'javascript'
        }),
        new CompositeDecorator(
          [{
            strategy: findEntities.bind(null, 'LINK', context),
            component: Link
          } ]
        ),
        //generateDecorator("hello")

      ])
      }
  }

  generateDecorator = (highlightTerm) => {
    const regex = new RegExp(highlightTerm, 'g');
    return new CompositeDecorator([{
      strategy: (contentBlock, callback) => {
        console.info("processing entity!", this.state.incomingSelectionPosition.length)
        if( this.state.incomingSelectionPosition.length > 0){

          findSelectedBlockFromRemote(
            this.state.incomingSelectionPosition, 
            contentBlock, 
            callback
           )
        }
        /*if (highlightTerm !== '') {
          findWithRegex(regex, contentBlock, callback);
        }*/
      },
      component: this.searchHighlight,
    }])
  };

  searchHighlight = (props) => {
    const sel = this.state.incomingSelectionPosition.find((o)=> o.anchorKey === props.children[0].props.block.getKey())

    return <SelectionIndicator style={{background: "yellow" }}>
            <UserIndicator>
              {sel.user}
              <i className="arrow"></i>
            </UserIndicator>
            {props.children}
          </SelectionIndicator>
  }

  widgetsConfig = ()=>{
    return [ CodeBlockConfig(),
            ImageBlockConfig({
                options: {
                  upload_url: `${config.endpoint}/uploads?book_id=${this.props.book.slug}&article_id=${this.props.article.slug}`,
                  //upload_handler: this.handleUpload,
                  image_caption_placeholder: "type a caption (optional)"
                }
            }), 
             DividerBlockConfig(),
             EmbedBlockConfig({ breakOnContinuous: true,
                                editable: true,
                                options: {
                                      placeholder: "put an external links",
                                      endpoint: `${config.endpoint}/oembed?url=`
                                    } 
                              }),
             VideoBlockConfig({ breakOnContinuous: true,
                                options: {
                                    placeholder: "put embed link ie: youtube, vimeo, spotify, codepen, gist, etc..",
                                    endpoint: `${config.endpoint}/oembed?url=`,
                                    caption: 'optional caption'
                                  } 
                                }),
             PlaceholderBlockConfig(),
             VideoRecorderBlockConfig({
                options: {
                  seconds_to_record: 20000,
                  upload_url: `${config.endpoint}/uploads?book_id=${this.props.book.slug}&article_id=${this.props.article.slug}`,
                }
             }),
             GiphyBlockConfig(),
             SpeechToTextBlockConfig()
           ]
  }

  saveHandler = (editorContext, content)=> {
    if (JSON.stringify(content) === this.props.article.content){
      console.log("eql content, return on save")
      return
    }

    if (this.state.loading){
      console.log("loading! return on save")
      return
    }

    if (this.locked){
      console.log("locked! return on save")
      return
    }

    // evita bug de que se sobrelapa el guardado 
    // el articulo pra el primer articulo
    /*if( this.props.match.params.article_id && 
      this.props.match.params.article_id != this.props.article.slug )
      return
    */

    this.props.updateArticle({
      book: this.props.book,
      content: JSON.stringify(content), 
      article: this.props.article,
      public: this.props.article.public
    })

    this.locked = false
          
  }

  handleChangesOnCurrentContent = (oldContent, newContent)=>{

    let diff = []
    diff = jsondiff(oldContent, newContent);
    if(diff.length > 0)
      this.setState({diff: JSON.stringify(diff)})
    //console.log(this.ChannelEvents.notify(diff))
  }

  decodeEditorContent =(raw_as_json)=> {
    const new_content = convertFromRaw(raw_as_json)
    return EditorState.createWithContent(new_content )
  }

  handleRTCMessage = (data)=>{
    if(data.length === 0)
      return

    const jsonData = JSON.parse(data)

    if(Object.keys(jsonData).includes("position"))
      return this.handleRTCPosition(jsonData)

    //console.log("REPLACE CONTENT: ", jsonData)

    const newDoc = ot.type.apply(this.state.currentContent, jsonData)

    // extract this and draw remote user's caret
    //delete newDoc['selectionAfter']
    //delete newDoc['selectionBefore']

    const decodedDoc = this.decodeEditorContent(newDoc)
    const newEditorState = EditorState.set(decodedDoc, {
      decorator: this.dante_editor.decorator
    });
    this.dante_editor.onChange(newEditorState)
  }

  handleRTCPosition = (data)=>{
    const a = []
    a.push(data.position)
    
    if(!data.position)
      return

    //debugger

    //console.log("POS: ", a)
    this.setState({
      incomingSelectionPosition: a
    })
  }

  toggleVideoSession = (e)=>{
    this.setState({
      videoSession: !this.state.videoSession
    })
  }

  renderSelection = (position)=>{
    //const positionObject = position //this.state.selectionPosition
    
    const pos = Object.assign({}, 
        position, 
        {  
          width: position.width === 0 ? 2 : position.width,
          top: position.top + window.pageYOffset 
        }) 
    console.log("post mada: ", pos)
    return <SelectionIndicator style={pos}>
      <span>
        {this.props.currentUser.email}
        <i className="arrow"></i>
      </span>
    </SelectionIndicator>
  }

  handleResize = ()=>{
    if(this.menuResizeFunc){
      //console.log("POSPOSP")
      this.setState({selectionPosition: this.menuResizeFunc(window)})
    }
  }

  render(){
    // !this.state.loading &&
    if( this.state.loading){
      return <Loader/>
    }

    if(isEmpty(this.props.article)){
      return null
    }

    return <ArticlePad>
              <RtcView 
                currentUser={this.props.currentUser.email}
                diff={this.state.diff}
                handleRTCMessage={this.handleRTCMessage}
                video={this.state.videoSession}
                book={this.props.book.slug}
                article={this.props.article.slug}
                toggleVideoSession={this.toggleVideoSession}
                selectionPosition={this.state.selectionPosition}
               />

            
               { /*
                 this.state.incomingSelectionPosition.map((o)=>{
                    return this.renderSelection(o)
                 }) 
               */}
            

              <Dante 
                  ref="editor"
                  data_storage={
                    { url: "/", 
                      save_handler: this.saveHandler 
                    }
                  }
                  onChange={(e)=>{
                    this.dante_editor = e
                    const newContent = convertToRaw(e.state.editorState.getCurrentContent()) //e.state.editorState.getCurrentContent().toJS()
                    this.menuResizeFunc = getVisibleSelectionRect
                    const selectionState = e.state.editorState.getSelection();
                                      
                    /*if(window.getSelection().rangeCount > 0){
                      window.getSelection().getRangeAt(0)
                      debugger
                    }*/
                    //console.log("MENU POSITION", this.menuResizeFunc(window))
                    this.setState({
                      currentContent: newContent, 
                      selectionPosition: selectionState.toJSON() //this.menuResizeFunc(window),
                    })
                    
                    //console.log("cha chachanges: ", e.state.editorState)
                  }}
                  content={JSON.parse(this.props.article.content)}
                  tooltips={this.tooltipsConfig()}
                  widgets={this.widgetsConfig()}
                  decorators={ (context)=> {
                    return new MultiDecorator([
                      this.generateDecorator("hello"),
                      PrismDraftDecorator({prism: Prism }),
                      new CompositeDecorator(
                        [{
                          strategy: findEntities.bind(null, 'LINK', context),
                          component: Link
                        } ]
                      )

                    ])
                    }
                  }
              /> 
          </ArticlePad>                      
  }

}