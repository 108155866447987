import React from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  handleConfirm = (e)=>{
    this.props.handleConfirm(e)
    this.toggle()
  }

  toggle() {
    this.props.toggle()
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} 
             toggle={this.toggle} 
             className={this.props.className}>
        <ModalHeader 
          toggle={this.toggle}>
          {this.props.title}
        </ModalHeader>

        <ModalBody>
          {this.props.children}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" 
            onClick={this.handleConfirm}>
            Confirm
          </Button>
          {' '}
          <Button color="secondary" 
            onClick={this.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

    );
  }
}

export default ModalExample;