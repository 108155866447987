import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyBt7kNAjWxCuy79ynMunioAx911xZ8TVTU",
  authDomain: "proseflow-8375c.firebaseapp.com",
  databaseURL: "https://proseflow-8375c.firebaseio.com",
  projectId: "proseflow-8375c",
  storageBucket: "proseflow-8375c.appspot.com",
  messagingSenderId: "718248270737"
};

firebase.initializeApp(config)
const database = firebase.database()

export const auth = firebase.auth()

export const provider = new firebase.auth.GoogleAuthProvider()

export const storage = firebase.storage()

//window.storage = storage

export default database;