import React, { Component } from 'react';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import CustomThemeNodeContentRenderer from "./contentRenderer"
import {FaPencil, FaTimesCircle} from 'react-icons/lib/fa'


export default class Tree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: this.props.data,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.data != this.props.data){
      this.setState({treeData: this.props.data})  
    }
  }

  render() {
    return (
      <div style={{ height: 400 }}>
        <SortableTree
          //theme={CustomThemeNodeContentRenderer()}
          treeData={this.state.treeData}
          onChange={treeData => this.setState({ treeData }, 
            ()=>{this.props.onChange(treeData)}
          )}

            generateNodeProps={rowInfo => ({
              title: ( <div className="label-area"> 
                  <a href="#" 
                    onClick={(e)=> (this.props.handleClick(e, rowInfo))}>
                    {rowInfo.node.title}
                  </a> 
                </div> ),
              icons: [
                    <div
                      style={{
                        borderLeft: 'solid 8px gray',
                        borderBottom: 'solid 10px gray',
                        marginRight: 10,
                        boxSizing: 'border-box',
                        width: 16,
                        height: 12,
                        filter: rowInfo.node.expanded
                          ? 'drop-shadow(1px 0 0 gray) drop-shadow(0 1px 0 gray) drop-shadow(0 -1px 0 gray) drop-shadow(-1px 0 0 gray)'
                          : 'none',
                        borderColor: rowInfo.node.expanded ? 'white' : 'gray',
                      }}
                    />,
                  ]
              ,
              buttons: [
                <a className="btn btn-link btn-sm mr-1" 
                  style={{
                    padding: 0,
                    borderRadius: '100%',
                    backgroundColor: 'gray',
                    color: 'white',
                    width: 22,
                    height: 22,
                    border: 0,
                    fontWeight: 100,
                  }}
                  onClick={() => this.props.handleArticleEdit(rowInfo)}
                >

                <FaPencil/>
                  
                </a>,


                <a className="btn btn-link btn-sm mr-1" 
                  style={{
                    padding: 0,
                    borderRadius: '100%',
                    backgroundColor: 'gray',
                    color: 'white',
                    width: 22,
                    height: 22,
                    border: 0,
                    fontWeight: 100,
                  }}
                  onClick={(e) => this.props.handleDelete(e, rowInfo)}
                >
                  <FaTimesCircle/>
                </a>

              ],
            })}
        />
      </div>
    );
  }
}