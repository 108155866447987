import React from 'react'
import {
  EditorBlock
} from 'draft-js'

import styled from '@emotion/styled'

const Anchor = styled.a`
  line-height: 1;
  margin-left: -28px;
  opacity: ${props => props.displayAnchor ? 100 : 0};
  padding-right: 4px;
  transition: opacity .3s;
  float: left;
`

//${props => props.displayAnchor ? 100 : 0};;

export default class HeadingTwoBlock extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      displayAnchor: false
    }
  }

  displayAnchor = (e)=>{
    this.setState({displayAnchor: true})
  }

  hideAnchor = (e)=>{
    this.setState({displayAnchor: false})
  }


  render(){
    return <React.Fragment>
      
      <Anchor 
        id={this.props.block.getKey()}
        displayAnchor={this.state.displayAnchor}
        onMouseOver={this.displayAnchor} 
        onMouseOut={this.hideAnchor}
        href={`#${this.props.block.getKey()}`} 
        aria-hidden="true">
        
        <svg 
          className="hash-link-icon" 
          aria-hidden="true" 
          height="16" version="1.1" 
          viewBox="0 0 16 16" width="16">
          <path fill-rule="evenodd" 
          d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z">
          </path>
        </svg>

      </Anchor>

      <div 
        onMouseOver={this.displayAnchor} 
        onMouseOut={this.hideAnchor}>

        <EditorBlock {...Object.assign({}, this.props, { 
            "editable": true, "className": "imageCaption" })
          } 
        />
      </div>

    </React.Fragment>
  }
}

export const HeadingTwoBlockConfig = (options={})=>{
  let config =  {
    title: 'header-two',
    type: 'header-two',
    //icon: image,
    wrapper_class: "graf graf--h3",
    block: HeadingTwoBlock,
    editable: true,
    renderable: true
  }
    
  return Object.assign(config, options)
} 