import React from 'react'
import {
  HashRouter,
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from 'react-router-dom'
import LockedView from './locked_view.js'


const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

export const PrivatePropsRoute = ({ component, ...rest }) => {
  return <Route {...rest} render={props => {
    return rest.authenticated ? (
              renderMergedProps(component, props, rest)
            ) : (
              <LockedView handleClick={rest.handleLogin}/>
            )
    }
  }/>
}

export const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }}/>
  );
}