import React, {Component, Fragment} from 'react'
import Sidebar from './sidebar.js'
//import DanteApp from './dante.js'
//import DemoApp from './demo_app.js'
//import DemoApp from "dante2-extended/src/editor"

import uuidV4 from 'uuid/v4';
import Loader from './loader.js'
import ModalExample from "./modal"

import config from "../constants/config"
import Cover from './cover'
import plants from "../images/plants.png"


import { toast } from 'react-toastify';


import {
  Sidebar as SidebarStyle,
  Nav,
  Card,
  FixedCol,
  TitleContent,
  TitleLogo,
  Main,
  ContentBand,
  UserInfo,
  CardTitle
} from "./styledBlocks"

import {
  Route,
  Link as RouterLink,
  BrowserRouter as Router,
} from 'react-router-dom'

import graphql from '../graphql/client'
import {
  UPLOAD_IMAGE
} from '../graphql/mutations'

import NewArticleForm from './article/newForm'
import Article from './article'
import UserConfigForm from './userConfigForm'
import BookConfig from './bookConfig'
import {
  isEmpty
} from 'lodash';

import {
        PrivatePropsRoute,
        PropsRoute
       } from './router_utils.js'



export default class ArticleEditor extends React.Component {

  constructor(props){
    super(props)
    this.endpoint     = this.endpoint.bind(this)
    this.handleConfirmArticle = this.handleConfirmArticle.bind(this)
    this.defaultUUID = uuidV4() 
    this.locked = false

    this.enpointRef = null
    this.summariesRef = null
    this.current_ID = null

    this.state =  {
      article: null,
      loading: true,
      endpointKey: null,
      configForm: false,
      collapsed: true,
      showArticleModal: false
    }
  }

  endpoint(){
    let url = this.state.endpointKey 
    return '/articles/' + url
  }

  stylesForCollapsed = ()=>{
    return this.state.collapsed ? 
    {sidebar: null, content: "11"} : 
    {sidebar: "3", content: "9"} 
  }

  showArticleForm = (e)=>{
    e.preventDefault()
    this.setState({
      showArticleModal: true
    })
  }

  handleConfirmArticle = (ev)=>{
    ev.preventDefault()
    this.props.newChapter(
      this.props.book, 
      this.props.summary, 
      this.refs.article_form.refs.article_title.value,
      {
        success: (article)=>{
          this.props.history.push("/books/" + this.props.book.slug + "/article/" + article.slug )
        }
      }
    )
    this.setState({showArticleModal: false})
  }

  renderEmptyArticleView = ()=>{
    return <div className="container-fluid">
      <Cover 
        title={`This book has no articles yet!`}
        description={`Add your first article to ${this.props.book.title}`}>
        
        <div className="d-flex align-items-start flex-column">
          <img src={plants} className="mb-auto p-2 align-self-center" 
            width='40%'
          />

          <button type="button" 
            onClick={this.showArticleForm}
            className="btn btn-lg mt-3 btn-primary p-2 align-self-center"  
            ref="btn">
            <i className="fa fa-plus"></i> 
            {" "}
            Create your first article
          </button>
        </div>
      </Cover>

    </div>
  }

  toggleSidebar = (e)=>{
    this.setState({collapsed: !this.state.collapsed })
  }

  componentWillUnmount(){
    this.props.resetBook()
  }

  componentDidMount(){
    this.props.resetBook()
    this.props.onGetBook(this.props.match.params.id, 
      { 
        success: (book)=>{
          if (!this.props.match.params.article_id){
            console.log("fetch first article: article match:", this.props.match.params.article_id)

            this.props.getArticle(book,
              this.props.match.params.article_id,
              {
                success: () => {
                  this.setState({ loading: false })
                },
                error: () => {
                  this.setState({ loading: false })
                }
              }
            )
          }
          
        }, 
        error: ()=>{
          this.setState({loading: false}) 
        }
      }
    )
  }

  render(){
    return (
      <React.Fragment>


        <ModalExample 
          toggle={()=> this.setState({showArticleModal: !this.state.showArticleModal})}
          isOpen={this.state.showArticleModal} 
          handleConfirm={this.handleConfirmArticle}>
          <NewArticleForm ref="article_form" />
        </ModalExample>


        { !isEmpty(this.props.book) && !isEmpty(this.props.book.treeData) ?

            <React.Fragment>
              
              <Sidebar 
                col={this.stylesForCollapsed().sidebar}
                toggleSideBar={this.toggleSidebar}
                showConfigForm={this.showConfigForm}
                collapsed={this.state.collapsed}
                f={this.props.f} 
                {...this.props}
              /> 

              <Main className={`container-fluid main-content`}>
                  {this.props.renderMenu()}

                  <div className="entry-inner">
                    
                    <div className="d-flex">
                      
                      <div className="p-2">

                        <a className="btn btn-default btn-md m-1" 
                          href="#" 
                          onClick={this.showArticleForm}>
                          <i className="fa fa-plus"></i>
                          {" "}
                          ADD ARTICLE
                        </a>

                      </div>

                      <div className="p-2">
                        <RouterLink className="btn btn-default btn-md m-1" 
                          //href="#" 
                          //onClick={this.showConfigForm}
                          to={"/books/" + this.props.book.slug + "/config"}
                          >
                          <i className="fa fa-cog"/>
                          {" "}
                          BOOK CONFIG
                        </RouterLink>
                      </div>

                      <div className="p-2">


                        {
                          !this.props.book.public ?

                  
                            <RouterLink 
                              className="btn btn-default btn-md m-1" 
                              to={`/public/books/${this.props.book.password}`}>
                              <i className="fa fa-mask"></i>
                              {" "} PRIVATE LINK
                            </RouterLink> : null

          
                        }

                      </div>


                      <div className="ml-auto p-2">
                        {
                          this.props.book.public ?
                        
                            <RouterLink 
                              className="btn btn-link btn-md m-1"
                              to={"/public/books/" + this.props.book.slug}>
                              <i className={`fa fa-unlock`}/>
                              {" "} 
                              VISIT BOOK
                              {" "} 
                              <i className="fa fa-arrow-right"/>
                            </RouterLink> : null
                        }

                      </div>

                    </div>

                    {/*
                    <button className="float-right btn btn-primary btn-md m-1" 
                      onClick={this.saveContent}>
                      publish
                    </button>
                    */}
                 
                   

                    <PrivatePropsRoute exact 
                      path={`${this.props.match.path}/invitations` }
                      component={UserConfigForm}
                      authenticated={this.props.authenticated}
                      currentUser={this.props.currentUser}
                      book={this.props.book}
                      onGetBook={this.props.onGetBook}
                    />

                    <PrivatePropsRoute 
                      path={`${this.props.match.path}/config` }
                      component={BookConfig}
                      authenticated={this.props.authenticated}
                      currentUser={this.props.currentUser}
                      book={this.props.book}
                      onGetBook={this.props.onGetBook}
                      updateBook={this.props.updateBook}
                      exportDoc={this.props.exportDoc}
                    />

                    <PrivatePropsRoute exact 
                      path={`${this.props.match.path}`}
                      component={Article}
                      saveHandler={this.saveHandler}
                      authenticated={this.props.authenticated}
                      currentUser={this.props.currentUser}
                      article={this.props.article}
                      book={this.props.book}
                      onGetBook={this.props.onGetBook}
                      ChannelApp={this.props.ChannelApp}
                      setState={this.setState}
                      resetBook={this.props.resetBook}
                      onGetBook={this.props.onGetBook}
                      getArticle={this.props.getArticle}
                      updateArticle={this.props.updateArticle}
                      onGetBook={this.props.onGetBook}
                    />


                    <PrivatePropsRoute exact 
                      path={`${this.props.match.path}/article/:article_id`} 
                      component={Article}
                      saveHandler={this.saveHandler}
                      authenticated={this.props.authenticated}
                      currentUser={this.props.currentUser}
                      article={this.props.article}
                      book={this.props.book}
                      setState={this.setState}
                      ChannelApp={this.props.ChannelApp}
                      resetBook={this.props.resetBook}
                      onGetBook={this.props.onGetBook}
                      getArticle={this.props.getArticle}
                      updateArticle={this.props.updateArticle}
                    />

                    {/*this.renderEditor()*/}
                 
               

                  </div>
              </Main>
            </React.Fragment> : null 
        }

        {
          !this.state.loading && this.props.book && isEmpty(this.props.book.treeData) ? 
          this.renderEmptyArticleView() : null
        }
      
      </React.Fragment>
    )
  }

}

