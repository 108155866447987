import React, {Fragment} from 'react'
import ReactDOM from 'react-dom'
import slug from 'slug'
import uuidV4 from "uuid"
import $ from "jquery";

import ModalExample from './modal'

import {FaPencil, FaTimesCircle} from 'react-icons/lib/fa'

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

import {
  Link
} from 'react-router-dom'

import { DragDropContext } from 'react-dnd';

import HTML5Backend from 'react-dnd-html5-backend';

import Tree from "./sortable/dnd"
import UserConfigForm from './userConfigForm'


export default class Sidebar extends React.Component {

  constructor(props){
    super(props)
    //this.state =  {
    //  summary: []
    //}
    this.toggle = this.toggle.bind(this);
    this.changeTitle = this.changeTitle.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.state= {
      showModal: false,
      showArticleModal: false,
      articleToEdit: null
    }
  }

  componentDidMount(){
  }

  componentDidUpdate(prevProps, prevState) {
  }

  changeTitle(index, name, item){
    // book, article, item, summary, value, index
    this.props.changeTitle({
      book: this.props.book, 
      article: this.props.article,
      item: item,
      summary: this.props.summary, 
      value: name,
      index: index
    })
  }

  removeItem(index, item){
    const book = this.props.book
    const article = this.props.article
    const props = this.props
    this.props.removeChapter({
      book: this.props.book, 
      article: item
    }, 
    {
      success: ()=>{
        // only if we delete current article
        if(article.slug === item.slug)
          this.props.history.push("/books/" + this.props.book.slug)
      },error: (err)=>{
        console.log(err)
      }
    })

    //this.props.history.push("/books/" + this.props.book.title)
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  renderNewArticleForm(){
    return (
      <form>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Title</label>
          <input 
            type="email" 
            className="form-control" 
            id="exampleInputEmail1" 
            aria-describedby="emailHelp" 
            placeholder="Enter article title"
            ref="article_title"
          />
          <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <input type="checkbox" className="form-check-input"/>
            Keep this article private
          </label>
        </div>
      </form>
    )
  }

  showUserConfigForm = ()=>{
    return <UserConfigForm/>
  }

  renderEditArticleForm = (node)=>{
    return <form>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Title</label>
          <input 
            type="email" 
            className="form-control" 
            id="exampleInputEmail1" 
            aria-describedby="emailHelp" 
            placeholder="Enter article title"
            defaultValue={this.state.articleToEdit.title}
            ref="article_title"
          />
          <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <input type="checkbox" refs="public_check" className="form-check-input"/>
            Keep this article private
          </label>
        </div>
      </form>
  }

  renderIndexTrees(){
    if(!this.props.book.treeData){
      return null
    }

    return this.props.book.treeData.map((o, i)=>{
      return <IndexEntry 
          key={i}
          index={i} 
          book={this.props.book}
          article={this.props.article}
          article_key={o}
          changeTitle={this.changeTitle}
          removeItem={this.removeItem}
          item={o} 
        />
    })
  }

  handleConfirm = (ev)=>{
    ev.preventDefault()
    this.props.newChapter(
      this.props.book, 
      this.props.summary, 
      this.refs.article_title.value,
      {success: (article)=>{
        this.props.history.push("/books/" + this.props.book.slug + "/article/" + article.slug )

      }}
    )

    this.setState({showModal: false})
  }

  handleEditConfirm = (ev)=>{
    console.log(this.state)
    console.log(this.refs)
    console.log(this.props)

    this.props.changeTitle({
      book: this.props.book, 
      value: this.refs.article_title.value, 
      item: this.state.articleToEdit,
      pub: true // TODO: implement
    })

  }

  createNewModal = (e)=>{
    e.preventDefault()
    this.setState({
      showModal: true
    })
  }

  editArticleModal = (e)=>{
    e.preventDefault()
    this.setState({
      showArticleModal: true
    })
  }

  handleDndChange = (data)=>{
    this.props.updateTreeData({
      book: this.props.book, 
      data: data
    })
  }

  handleDelete = (e, node)=>{
    e.preventDefault()
    this.removeItem(null, node.node)
  }

  handleArticleEdit = (data)=>{
    //e.preventDefault()
    this.setState({
      showArticleModal: true,
      articleToEdit: data.node
    })
  }

  handleClickOnArticleTitle = (e, rowInfo)=>{
    e.preventDefault()
    this.props.history.push("/books/" + this.props.book.slug + "/article/" + rowInfo.node.slug)
  }



  render(){

    return (

      <div className={`${this.props.col ? 'col-'+this.props.col : ''} align-self-stretch main-sidebar d-none d-md-block`}>
        <div className="Sidebar">
          <div className="d-flex align-items-end flex-column">
            
            <div className="p-2 align-self-center">
                <button className={`btn btn-default btn-md ${this.props.collapsed ? 'active--' : null}`} 
                  onClick={this.props.toggleSideBar}>
                  <i className={`fa fa-book${this.props.collapsed ? '' : '-open'} fa-2x`}/>
                  <br/>
                  <span className="text-muted" 
                    style={{fontSize: '.56em'}}>
                    {
                      this.props.collapsed ? "VIEW INDEX" : "HIDE INDEX"
                    }
                    
                  </span>
                </button>
            </div>


            <div className="p-1 align-self-center">

              <button type="button" 
                onClick={this.createNewModal}
                className="btn btn-default btn-md"  
                ref="btn">
                <i className="fa fa-2x fa-plus"></i>
                <br/>
                <span className="text-muted" 
                  style={{fontSize: '.56em'}}>
                  ADD ARTICLE
                </span>
              </button>

              <ModalExample 
                toggle={()=> this.setState({showModal: !this.state.showModal})}
                isOpen={this.state.showModal} 
                handleConfirm={this.handleConfirm}>
                {this.renderNewArticleForm()}
              </ModalExample>

            </div>

            {
              this.props.collapsed ? <Fragment>

                <div className="p-1 align-self-center">
                  <Link className="btn btn-default btn-md" 
                    to={"/books/" + this.props.book.slug + "/config" }>
                    <i className="fa fa-2x fa-cog"/>
                    <br/>
                    <span className="text-muted"
                      style={{fontSize: '.56em'}}>
                      BOOK CONFIG
                    </span>
                  </Link>
                </div>


                <div className="p-1 align-self-center">
                  <Link className="btn btn-default btn-md" 
                    to={`/books/${this.props.book.slug}/invitations`}
                    >
                    <i className="fa fa-2x fa-user-friends"/>
                    <br/>
                    <span className="text-muted"
                      style={{fontSize: '.56em'}}>
                      USERS CONFIG
                    </span>
                  </Link>
                </div>

                

              </Fragment> : null
            }



          </div>

          {

            !this.props.collapsed ?

              <Fragment>

               <div className="pagehead invisible">
                  <ul className="menu">
                     <li className="active"><a href="#">
                      <span>ToC</span></a>
                     </li>
                     <li className="">
                      <a href="#">Files</a>
                     </li>
                  </ul>
               </div>

               <div className="Sidebar-Body">

                  {
                    !this.props.book.treeData ? null : 
                    <div>
                      <Tree 
                        data={this.props.book.treeData}
                        onChange={this.handleDndChange}
                        handleDelete={this.handleDelete}
                        handleClick={this.handleClickOnArticleTitle}
                        handleArticleEdit={this.handleArticleEdit}
                      />
                    </div>
                  }


                  {
                    this.state.showArticleModal && this.state.articleToEdit ?
                  
                      <ModalExample 
                            toggle={()=> this.setState({showArticleModal: !this.state.showArticleModal})}
                            isOpen={this.state.showArticleModal} 
                            handleConfirm={this.handleEditConfirm}>
                            {this.renderEditArticleForm()}
                      </ModalExample> : null 
                  }

                  <ModalExample 
                    toggle={()=> this.setState({showModal: !this.state.showModal})}
                    isOpen={this.state.showModal} 
                    handleConfirm={this.handleConfirm}>
                    {this.renderNewArticleForm()}
                  </ModalExample>

               </div>

             </Fragment> : null

          }
        </div>
      </div>
    )
  }
}


class IndexEntry extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      editable: false,
      displayConfirm: false
    }

    this.handleDelete = this.handleDelete.bind(this)

    this.handleEditable = this.handleEditable.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleEditable(e){
    e.preventDefault()
    this.setState({editable: !this.state.editable})
  }

  handleChange(e){
    if (e.key === 'Enter') {
      this.props.changeTitle(this.props.book, 
        this.refs.input.value, 
        this.props.item)
      
      this.setState({editable: !this.state.editable})
    }
  }

  displayConfirm(e){
    this.setState({displayConfirm: true})
  }

  handleClick(e, data) {
    console.log(data)
  }

  renderRemoveModal(){
    return (

        <div className="modal fade" 
          id={"exampleModal" + this.props.item.article_id }
          tabIndex="-1" role="dialog" 
          aria-labelledby="exampleModalLabel" 
          aria-hidden="true">
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Remove Article
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Remove Item {this.props.item.title}
              </div>
              <div className="modal-footer">
                <button 
                  type="button" 
                  className="btn btn-secondary" 
                  data-dismiss="modal">
                  Close
                </button>
                <button type="button" 
                  className="btn btn-primary"
                  onClick={this.handleRemove}>
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
    )
  }

  handleDelete = (e)=>{
    e.preventDefault()
    this.props.removeItem(this.props.book, this.props.article_key)
  }

  isSelected = ()=>{
    if(this.props.article){
      return this.props.item.slug === this.props.article.slug ? 'bold' : 'normal'
    }
    else {
      return 'normal'
    }
  }

  render(){
    return (
      <div className="TreeEntry">
        {
          this.renderRemoveModal()
        }

        <div className="inner" draggable="true" 
          style={{paddingLeft: '10px'}}>

           <span className="name">

           <ContextMenuTrigger id={"1"} holdToDisplay={1000}>
              <span className="SpanInput" 
                style={{ fontWeight: this.isSelected() }}>
               {
                !this.state.editable ? 
                <Link to={"/books/"+ this.props.book.slug + "/article/" + this.props.item.slug }>
                {this.props.item.title}
               </Link> : <input type="text" 
                                ref="input" 
                                defaultValue={this.props.item.title}
                                onKeyPress={this.handleChange}/>
               }
              </span>

              <ContextMenu id={"1"}>
                <MenuItem onClick={this.handleRemove} 
                  data={{ menu: 1, item: 1 }}>
                  Remove
                </MenuItem>
                <MenuItem onClick={this.handleEditable} 
                  data={{ menu: 1, item: 2 }}>
                  Edit
                </MenuItem>
              </ContextMenu>

              <div className="float-right">
                <a href="#" 
                  className="btn btn-link btn-sm" 
                   onClick={this.handleEditable}>

                  <FaPencil/>
                </a>

                <a href="#"
                  className="btn btn-link btn-sm" 

                  onClick={this.handleDelete } 
                  >
                  <FaTimesCircle/>
                </a>
              </div>
            </ContextMenuTrigger>
           </span>
        </div>
      </div>
    )
  }
}






