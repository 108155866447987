import ActionTypes from '../constants/action_types';
import database from './database';
import {auth, provider, storage} from './database';
import slug from 'slug';
import uuidV4 from 'uuid/v4';
import ImageCompressor from '@xkeshi/image-compressor';
import db from './db'
import { toast } from 'react-toastify';

import graphql from '../graphql/client'
import {BOOK, ARTICLE, USER_BOOK, USER_ARTICLE} from '../graphql/queries'
import {
  ADD_BOOK, 
  ADD_ARTICLE,
  EDIT_BOOK, 
  UPDATE_ARTICLE,
  REMOVE_ARTICLE,
  UPDATE_BOOK_TREE,
  REMOVE_BOOK,
  GENERATE_WORD
} from '../graphql/mutations'

//provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

export function addBook(opts, cb){

  return dispatch => {

    let book = {
        title: opts.title,
        description: opts.description,
        public: opts.public,
      }

    graphql(ADD_BOOK, book, {
      success: (data)=>{
        if(cb && cb.success)
          cb.success(data.addBook)
      },
      error: (err)=>{
        if(cb && cb.error)
          cb.error(err)
        toast.error("error adding book")
      }
    })
  }
}

export function removeBook(opts, cb){
  return dispatch =>{
    graphql(REMOVE_BOOK, opts, {
      success: (data)=>{
        dispatch(setNewBook( {} ))
        if(cb && cb.success)
          cb.success({})
      },
      error: (err)=>{
        if(cb && cb.error)
          cb.error(err)
        toast.error("error removing book")
      }
    })
  }
}

export function getBook(id, cb){
  return dispatch => {
    graphql(BOOK, {id: id}, {
      success: (data)=>{
        dispatch(setNewBook( data.book ))
        if (cb && cb.success)
          cb.success(data.book)
      },
      error: (err)=>{
        if (cb && cb.error)
          cb.success(err)
        toast.error("error getting book")
      }
    })
  }
}


export function getUserBook(id, cb){
  return dispatch => {
    graphql(USER_BOOK, {id: id}, {
      success: (data)=>{
        dispatch(setNewBook( data.userBook ))
        if (cb && cb.success)
          cb.success(data.userBook)
      },
      error: (err)=>{
        if (cb && cb.error)
          cb.error(err)
        toast.error("error getting user book")
      }
    })
  }
}

export function resetBook(){
  return dispatch => {
    return dispatch(reset())
  }
}

export function updateBook(opts){
  return dispatch => {
    console.log(opts)
    graphql(EDIT_BOOK, opts, {
      success: (data)=>{
        dispatch(setNewBook(data.editBook.book))
      },
      error: (err)=>{
        toast.error("error updating book")
      }
    })
  }
}

export function updateTreeData(opts){
  return dispatch =>{
    graphql(UPDATE_BOOK_TREE, {
      id: opts.book.slug, 
      tree: opts.data}, {
      success: (data)=>{
        getBook(opts.book.slug)
      },
      error: (err)=>{
        toast.error("error updating book tree")
      }
    })
  }
}

export function getArticle(bookId, article_id, cb){
  return dispatch => {
    graphql(ARTICLE, {bookId: bookId, id: article_id}, {
      success: (data)=>{
        dispatch(setArticle(data.article))
        if(cb.success)
          cb.success()  
      },
      error: (err)=>{
        if (cb && cb.error)
          cb.error(err)
        toast.error("error getting article")
      }
    })
  }
}

export function getUserArticle(book, article_id, cb){
  return dispatch => {
    //console.log(USER_ARTICLE)
    //console.log("aa", book.slug)
    graphql(USER_ARTICLE, {bookId: book.slug, id: article_id}, {

      success: (data)=>{
        dispatch(setArticle(data.userArticle))
        if(cb && cb.success)
          cb.success()  
      },
      error: (err)=>{
        console.log(err)
        if (cb && cb.error)
          cb.error(err)
        toast.error("error getting user article")
      }
    })
  }
}

export function exportDoc(book, cb){

  return dispatch => {
    //console.log(USER_ARTICLE)
    //console.log("aa", book.slug)
    graphql(GENERATE_WORD, {bookId: book.slug}, {

      success: (data)=>{
        if(cb && cb.success)
          cb.success()  
      },
      error: (err)=>{
        console.log(err)
        if (cb && cb.error)
          cb.error(err)
        toast.error("error getting user article")
      }
    })
  }
}

export function newChapter(book, summary, value, cb){
  //console.log(cb)
  return dispatch => {

    const demo = {
      "entityMap":{},
      "blocks":[
        {"key":"761n6","text":value,"type":"header-one","depth":0,"inlineStyleRanges":[{"offset":0,"length":11,"style":"BOLD"}],"entityRanges":[],"data":{}},
        {"key":"es43u","text":"start typing","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}
      ]}

    const article = { 
                      content: JSON.stringify(demo), 
                      title: value, 
                      public: true,
                      bookId: book.slug
                    }

    graphql(ADD_ARTICLE, article, {
      success: (data)=>{
        //dispatch(setNewBook( data.addArticle.book ))
        // TODO: make this more efficient
        dispatch(getUserBook(data.addArticle.book.slug ))
        if(cb && cb.success)
          cb.success(data.addArticle.article)
      },
      error: (err)=>{
        if (cb && cb.error)
          cb.error(err)
        toast.error("error adding article")
      }
    })

  }
}

export function changeTitle(opts){
 
  const {book, article, item ,value, pub} = opts

  return dispatch => {
    const params = {
      bookId: book.slug,
      articleId: item.slug,
      public: pub,
      content: null,
      title: value
    }

    graphql(UPDATE_ARTICLE, params, {
      success: (data)=>{  
        dispatch(setArticle(data.editArticle.article)) 
        // refresh book
        // TODO: call getBook
        dispatch(getBook(book.slug))
        //dispatch(setNewBook( book ))
      },
      error: (err)=>{
        toast.error("error updating article")
      }
    })

  }
}

export function updateArticle(opts){
  
  return dispatch => {
    const params = {
      bookId: opts.book.slug,
      content: opts.content,
      articleId: opts.article.slug,
      public: opts.article.public,
      title: opts.article.title
    }

    graphql(UPDATE_ARTICLE, params, {
      success: (data)=>{
        dispatch(setArticle(data.editArticle.article))  
      },
      error: (err)=>{
        toast.error("error adding article")
      }
    })

  }
}

export function removeChapter(opts, cb){
  const {book, article } = opts
  return dispatch => {
    graphql(REMOVE_ARTICLE, {
      bookId: book.slug, 
      articleId: article.slug
    }, {
      success: (data)=>{
        if(cb && cb.success)
          cb.success(data)
        dispatch(setNewBook(data.removeArticle.book))
      },
      error: (err)=>{
        if(cb && cb.error)
          cb.error(err)

        toast.error("error removing article")
      }
    })
  }
}

export function addFile(file, imageBlock, cb){

  return dispatch => {

    new ImageCompressor(file, {
      quality: .6,
      success(result) {
        const new_file = result
        const id = uuidV4()
        const promise = storage.ref().child(`images/${id}/${new_file.name}`).put(new_file)
        promise.on('state_changed', snapshot => {
          imageBlock.updateProgressBar({ 
            lengthComputable: true, 
            loaded: snapshot.bytesTransferred, 
            total: snapshot.totalBytes })
        })

        promise.then(data => {
          imageBlock.uploadCompleted(data.downloadURL)
        }).catch(() => {
          imageBlock.uploadFailed()
        })

      },
      error: (err)=>{
        toast.error("error uploading file")
      }
    });

  }
}

function setArticle(article) {
  return {
    type: ActionTypes.SetArticle,
    data: {article: article }
  };
}

function setBookSummary(book, summary) {
  return {
    type: ActionTypes.AddBookWithSummary,
    data: {book: book, summary: summary}
  };
}

function setNewBook(book) {
  return {
    type: ActionTypes.AddBook,
    data: book
  };
}

function getABook() {
  return {
    type: ActionTypes.GetBook
  }
}

function reset() {
  return {
    type: ActionTypes.ResetBook
  }
}