import ActionTypes from '../constants/action_types';
const default_state = { authenticated: false,  currentUser: null}
export function userReducer(state = default_state, action) {
  switch(action.type) {

    case ActionTypes.setCurrentUser: {
      return Object.assign({}, state, {
        authenticated: true, 
        currentUser: action.data
      });
    }

    case ActionTypes.setSignOut: {
      return Object.assign({}, state, {
        authenticated: false, 
        currentUser: null
      });
    }


    default:
      return state;
  }
}