/*import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
*/

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppContainer from './containers/app';
import store from './store';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/scss/bootstrap.scss';
import "./styles/base.scss"


//import registerServiceWorker from './registerServiceWorker';

import db from './actions/db';

window.db = db
window.store = store

const main = (
  <Provider store={store}>
      <AppContainer />
  </Provider>
);

ReactDOM.render(main, document.getElementById('root'));


//registerServiceWorker();
