import ActionTypes from '../constants/action_types';
const default_article = {article: {} , book: {}, summary: []}
export function bookReducer(state = default_article, action) {
  switch(action.type) {

    case ActionTypes.AddBook: {
      return Object.assign({}, state, {
        book: action.data
      });
    }

    case ActionTypes.ResetBook: {
      return {book: {}, summary: [], article: {}}
    }

    case ActionTypes.GetBook: {
      return Object.assign({}, state, {
        book: action.data 
      });
    }

    case ActionTypes.AddBookWithSummary: {
      return Object.assign({}, state, {
        book: action.data.book,
        summary: action.data.summary
      });
    }

    case ActionTypes.SetArticle: {
      //console.log("set article!!!", action.data.article)
      return Object.assign({}, state, {
        article: action.data.article
      });
    }

    default:
      return state;
  }
}