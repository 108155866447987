import { connect } from 'react-redux';
//import { getArticle } from '../actions/get_article';
//import { addArticle } from '../actions/add_article';
import { 
  handleLogin, 
  signOut, 
  checkCurrentUser
} from '../actions/auth'

import { 
  getBook,
  getUserBook,
  addBook,
  getArticle,
  getUserArticle,
  newChapter,
  changeTitle,
  removeChapter,
  updateArticle,
  addFile,
  resetBook,
  updateBook,
  updateTreeData,
  removeBook,
  exportDoc
} from '../actions/book'

import {
  getBooks,
  getUserBooks
} from '../actions/books'

//import { watchArticleAddedEvent } from '../actions/article_added_event';
//import Invite from '../components/invite.jsx';


import App from '../components/app.js';


function mapStateToProps(state) {
  return {
    invite: state.invite,
    currentUser: state.userReducer.currentUser,
    authenticated: state.userReducer.authenticated,
    book: state.bookReducer.book,
    summary: state.bookReducer.summary,
    article: state.bookReducer.article,
    books: state.booksReducer.books
  };
}

function mapDispatchToProps(dispatch) {
  //watchArticleAddedEvent(dispatch);
  //checkCurrentUser(dispatch)
  return {
    onLogin: () => dispatch(handleLogin()),
    onSignOut: () => dispatch(signOut()),
    onCheckCurrentUser: () => dispatch(checkCurrentUser()),
    onAddBook: (title, cb) => dispatch(addBook(title, cb)),
    onGetBook: (title, cb) => dispatch(getBook(title, cb)),
    onGetUserBook: (title, cb) => dispatch(getUserBook(title, cb)),
    getArticle: (book, article_id, cb) => dispatch(getArticle(book, article_id, cb)),
    getUserArticle: (book, article_id, cb) => dispatch(getUserArticle(book, article_id, cb)),
    newChapter:  (book, summary, value, cb) => dispatch(newChapter(book, summary, value, cb)),
    changeTitle: (opts ) => dispatch(changeTitle(opts)),
    removeChapter: (opts, cb) => dispatch(removeChapter(opts, cb)),
    getBooks: (user_id)=> dispatch(getBooks(user_id)),
    exportDoc: (book_id, cb)=> dispatch(exportDoc(book_id, cb)),
    getUserBooks: (user_id)=> dispatch(getUserBooks(user_id)),
    resetBook: ()=> dispatch(resetBook()),
    updateBook: (opts)=> dispatch(updateBook(opts)),
    removeBook: (opts, cb)=> dispatch(removeBook(opts, cb)),
    updateArticle: (opts)=> dispatch(updateArticle(opts)),
    updateTreeData: (opts, cb)=> dispatch(updateTreeData(opts)),
    addFile: (file, imageBlock, cb) => dispatch(addFile(file, imageBlock, cb))
  };
}

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;