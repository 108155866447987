import React from 'react'
import slug from 'slug'
import $ from "jquery";
import "../styles/home.scss"
import ModalExample from "./modal"
import graphql from '../graphql/client'
import {BOOKS} from '../graphql/queries'
import { toast } from 'react-toastify';
import {version} from '../../package.json'

import {
  Link
} from "react-router-dom"
import UserMenu from './userMenu'

import {
  Sidebar,
  Nav,
  Card,
  FixedCol,
  TitleContent,
  TitleLogo,
  Main,
  ContentBand,
  UserInfo,
  CardTitle
} from "./styledBlocks"

import {
  //HashRouter,
  BrowserRouter as Router,
  //Route,
  //Link,
  //Switch,
  //Redirect,
} from 'react-router-dom'

import {
        PrivatePropsRoute,
        PropsRoute
       } from './router_utils.js'

export default class Home extends React.Component {

  constructor(props){
    super(props)
    this.addNew = this.addNew.bind(this);
    this.endpoint = this.endpoint.bind(this)
    this.state = {
      showModal: false,
      collection: [],
      meta: {}
    }
  }

  componentDidMount(){
    graphql(BOOKS, {}, {
      success: (data)=>{
        this.setState( data.books )
      },
      error: (err)=>{
        toast("error getting books");
      }
    })
  }

  createNewModal = (e)=>{
    e.preventDefault()
    this.setState({
      showModal: true
    })
  }

  handleConfirm = (e)=>{
    this.addNew(e)
  }

  endpoint(){
    return "/books/" + slug(this.refs.article_title.value)
  }

  addNew(ev){
    const props = this.props
    this.props.onAddBook({
      title: this.refs.article_title.value, 
      description: this.refs.textArea.value ,
      //user_id: this.props.currentUser.email,
      public: this.refs.privateCheck.value == "on"
    }, {
      success: (data)=>{
        this.setState({
          showModal: false
        }, ()=> {
          props.history.push("/books/" + data.book.slug)
        })
      },
      error: (err)=>{
        console.log("error adding book")
      }
    })
  }

  renderNewBookForm(){
    return (
      <form>
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">
            Book Title
          </label>
          <input 
            type="email" 
            className="form-control" 
            id="exampleInputEmail1" 
            aria-describedby="emailHelp" 
            placeholder="Enter article title"
            ref="article_title"
          />
          <small id="emailHelp" className="form-text text-muted">
            We'll never share your email with anyone else.
          </small>
        </div>

        <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">Summary</label>
            <textarea className="form-control" rows="3" ref="textArea"></textarea>
        </div>

        <div className="form-check">
          <label className="form-check-label">
            <input type="checkbox" 
              ref="privateCheck" 
              className="form-check-input"
            />
            Keep this book public
          </label>
        </div>
      </form>
    )
  }

  renderTitle = ()=>{
    return  <TitleContent>
              <TitleLogo>
                ProseFlow
              </TitleLogo>

              <p className="display-4">
                Make books beautiful again
              </p>

              <Nav className="nav mb-4 mt-4 justify-content-between">
                <Link to={"/public/books/docs" } className="nav-link active" href="#">Docs</Link>
                <Link to={"/books"} className="nav-link" href="#">My Books</Link>
                <Link to={"/public/books/about"} className="nav-link" href="#">About</Link>
              </Nav>

              <h3>
                ProseFlow ({version}) helps your team write, collaborate and publish 
                content online.
              </h3>

              {
                this.props.authenticated ? 
              
                <p className="lead">
                  <a className="btn btn-success btn-lg" 
                    href="#"
                    onClick={this.createNewModal}>
                    Create a Book
                  </a>
                </p> : <p/>
              }

              <ModalExample 
                toggle={()=> this.setState({showModal: !this.state.showModal})}
                isOpen={this.state.showModal} 
                handleConfirm={this.handleConfirm}>
                {this.renderNewBookForm()}
              </ModalExample>
          </TitleContent>
  }

  render(){
    
    console.log(this.props.match.path)
    return (
      <React.Fragment>
        <Sidebar className="col-md-5 align-self-stretch ">
          <FixedCol className="col-5 position-fixed">

            {this.renderTitle()}

            {
              !this.props.authenticated ? 
              <TitleContent>
                <button 
                  onClick={this.props.handleLogin} 
                  className="btn btn-success">
                  sign up
                </button>
              </TitleContent> : null

            }
          </FixedCol>
        </Sidebar>

        <Main className="align-self-md-stretch">

          <UserInfo>

            <nav className="navbar navbar-expand-lg navbar-light">
              <h3>Create & publish</h3>
              
              <button className="navbar-toggler" 
                type="button" 
                data-toggle="collapse" 
                data-target="#navbarSupportedContent" 
                aria-controls="navbarSupportedContent" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <UserMenu {...this.props} className={'ml-auto'}/>
              </div>
            </nav>
      
          </UserInfo>


          <PublicBooksCollection  
            authenticated={this.props.authenticated}
            currentUser={this.props.currentUser}
            collection={this.state.collection}
          />
        </Main>
      </React.Fragment>
    )
  }
}


class BookItem extends React.Component{
  render(){
    return(


      <Card className="card">
                
                {
                  this.props.book.image ? 
                  <img className="card-img-top"
                      src={this.props.book.image} 
                      alt={this.props.book.title}/>  : null
                }
                
                
                <div className="card-body">
                    
                    <CardTitle>
                      <Link 
                        to={"/public/books/" + this.props.book.slug} 
                        className="card-link">
                        {this.props.book.title}
                      </Link>
                    </CardTitle>


                    {
                      /*
                      author: {book.user_id}, 
                      {book.private ? "private" : null}
                      */
                    }

                    <p className="card-text">
                      {this.props.book.description}
                    </p>

                </div>
      </Card>

    )
  }
}

class PublicBooksCollection extends React.Component{
  render(){
    return (

      <React.Fragment>
      
          {
            /*<ContentBand className="display-4 p-4"> 
            Create bolder book with proseflow platform. 
            Stay in the flow with a state of the art wysiwyg editor. 
            sell & create audiences around your creations. 
            </ContentBand>*/
          }

          {
            this.props.collection.map((o, i)=>{
              return <BookItem key={o.slug} book={o} />
            })
          }

      </React.Fragment>


    )
  }
}


class BooksCollection extends React.Component {


  constructor(props){
    super(props)
    //this.addNew = this.addNew.bind(this);
    this.state = {
      showModal: false
    }
  }

  componentDidMount(){
    this.props.getBooks(this.props.currentUser.email)
  }

  /*
  addNew(ev){
    this.props.onAddBook({
      title: this.refs.article_title.value, 
      description: this.refs.textArea.value ,
      user_id: this.props.currentUser.email,
      public: this.refs.privateCheck.checked
    }, 

    {success: (book)=> {
      this.setState({
        showModal: false
      }, ()=> {
        this.props.getBooks(this.props.currentUser.email)
        //this.props.history.push("/books/" + book.slug)
      })
    }}

    )
  }*/

  render(){
    return (
      <React.Fragment>
          
          {

            this.props.books ? <React.Fragment>
              

              <div className="mt-3 mb-3">
                <h1>Books</h1>
              </div>


              <div className="card-columns">
                {

                  Object.keys(this.props.books).map( (o,i)=>{
                    return ( <BookItem 
                            key={i} 
                            book={this.props.books[o]}
                            removeBook={this.props.removeBook}
                            getBooks={this.props.getBooks} />
                            )
                  })
                }
              </div>

            </React.Fragment> : null
          }

          
      </React.Fragment>
    )
  }
}

