const actionTypes = {
  GetInviteRequested: 'GET_INVITE_REQUESTED',
  GetInviteRejected: 'GET_INVITE_REJECTED',
  GetInviteFulfilled: 'GET_INVITE_FULFILLED',

  AddToInviteRequested: 'ADD_TO_INVITE_REQUESTED',
  AddToInviteRejected: 'ADD_TO_INVITE_REJECTED',
  AddToInviteFulfilled: 'ADD_TO_INVITE_FULFILLED',

  GuestAdded: 'GUEST_ADDED',

  AddBook: 'ADD_BOOK',
  GetBook: 'GET_BOOK',
  AddBookWithSummary: 'ADD_BOOK_WITH_SUMMARY',
  SetArticle: 'SET_ARTICLE',
  ResetBook: 'RESET_BOOK',

  GetBooks: 'GET_BOOKS',

  setCurrentUser: 'SET_CURRENT_USER',
  setSignOut: 'SET_SIGNOUT'
};

export default actionTypes;
