import ActionTypes from '../constants/action_types';
import database from './database';
import graphql from '../graphql/client'
import {BOOKS, USER_BOOKS} from '../graphql/queries'
import db from './db'
import { toast } from 'react-toastify';

export function getBooks(user_id){
  return dispatch => {
    graphql(BOOKS, {}, {
      success: (data)=>{
        dispatch(setBooks( data.userBooks.collection ))
      },
      error: (err)=>{
        toast.error("error getting books")
      }
    })
  }
}

export function getUserBooks(user_id){
  return dispatch => {
    graphql(USER_BOOKS, {}, {
      success: (data)=>{
        dispatch(setBooks( data.userBooks.collection ))
      },
      error: (err)=>{
        toast.error("error getting user books")
      }
    })
  }
}


function setBooks(books) {
  return {
    type: ActionTypes.GetBooks,
    data: books
  };
}
